import { IBuildingBillingInfo, IBillingBuilding, IBuilding, IBreadcrumbData, IBillableBuildingDate, IBillableDate } from './../../interfaces';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DataserviceService } from '../dataservice.service';
import { MatIcon } from '@angular/material/icon';
import { MatIconAnchor, MatIconButton, MatAnchor } from '@angular/material/button';
import { NgIf, DatePipe } from '@angular/common';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { ProgressspinnerComponent } from "../progressspinner/progressspinner.component";
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import { FormsModule, NgModel } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-billinglist',
    templateUrl: './billinglist.component.html',
    styleUrls: ['./billinglist.component.scss'],
    standalone: true,
    imports: [MatTooltip, FormsModule, MatFormField, MatLabel, MatSelect, MatOption, BreadcrumbnavComponent, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, NgIf, MatIconAnchor, MatIcon, MatIconButton, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, MatAnchor, DatePipe, ProgressspinnerComponent]
})
export class BillinglistComponent {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<IBillingBuilding>;
    monts: number[] = [];
    billmonth = 0;
    refValue = 0;
    currentId = 0;
    building: IBuilding;
    breadcrumb: IBreadcrumbData[] = [];
    progress = false;
    montsStart: number[] = [];
    referenceStart: number;
    referenceEnd: number;
    billablesDate: IBillableBuildingDate;

    constructor(private route: ActivatedRoute, public dialog: MatDialog, public dataservice: DataserviceService) {
        this.progress = true;
        this.monts = this.getLastMonts();


        this.route.paramMap.subscribe(async (map) => {
            // console.log('Pmap; ', map.get('id'));
            this.currentId = parseInt(map.get('id'), 10);

            this.dataservice.getBillBuildingList(this.currentId).subscribe(
                (data) => {
                    this.dataSource = new MatTableDataSource<IBillingBuilding>(data as IBillingBuilding[]);
                    const now = new Date();
                    this.referenceEnd = (now.getFullYear() - 2000) * 12 + now.getMonth();
                    this.referenceStart = this.referenceEnd - 11;
                    this.dataservice.getBuilding(this.currentId).subscribe((building) => {
                        this.dataservice.getBillDateBuildingList(this.currentId).subscribe(bdate => {
                            this.billablesDate = bdate as IBillableBuildingDate;
                            this.progress = false;
                            this.building = building as IBuilding;
                            this.breadcrumb.push({ label: 'HOME', url: '/' });
                            this.breadcrumb.push({ label: 'stabili', url: '/buildings' });
                            this.breadcrumb.push({ label: this.building.name, url: '/building/' + this.building.buildingId });
                            this.breadcrumb.push({ label: 'bollette', url: 'javascript:;' });
                        });
                    });
                },
                () => { }
            );

        });
    }

    change(e: MatSelectChange) {
    //    this.referenceStart = e.value - 12;
    }


    getClassBillable(item: IBillableDate) {
        var perc = 100 * item.billable / this.billablesDate.count;
        if (perc < 10) {
            return 'billable--bad';
        }
        if (perc < 50) {
            return 'billable--poor';
        }
        if (perc < 70) {
            return 'billable--good';
        }
        return 'billable--king';


    }
    getLastMonts() {
        const ret = [];
        const now = new Date();
        const nowIndex = (now.getFullYear() - 2000) * 12 + now.getMonth();
        // console.log(nowIndex);
        // const month = now.getMonth();
        // ret.push(new Date());
        for (let i = 0; i < 36; i++) {
            ret.push(nowIndex - i);
        }
        //  this.refValue = ret[0];
        return ret;
    }


    erase(item: IBillingBuilding) {
        this.dataservice.confirm('Vuoi eliminare la bolletta selezionata?', ['Si', 'No']).then((resp) => {
            if (resp === 0) {
                this.dataservice.deleteBilling(item.billingId).subscribe(() => {
                    this.dataservice.getBillBuildingList(this.currentId).subscribe(
                        (data) => {
                            this.dataSource = new MatTableDataSource<IBillingBuilding>(data as IBillingBuilding[]);
                        },
                        () => { }
                    );
                });
            }
        });
    }

    getClass(medium: number, element: IBillingBuilding) {
        if (undefined !== element.filter && element.filter != null && element.filter.includes(medium)) {
            return 'meter_icon' + medium;
        }
        return 'meter_iconOff';
    }

    getColumns() {
        return ['position', 'title', 'filters', 'timestamp', 'created', 'lastmodify', 'published', 'status', 'actions'];
    }

    canAdd() {
        console.log('Can Add', this.referenceEnd, this.referenceStart);
        return (this.referenceEnd - this.referenceStart > 1);
    }
}
