import { EditlabelComponent } from './editlabel/editlabel.component';
import { IBuilding, IConcentrator, IDictionary, ISysUser, IRadiatorDetail, IRadiatorParameter, ICompany, IBuildingBillingInfo, IMeterDeviceStats, IConcentratorLog, IMeterDeviceStatBillingInfo, IBillingBuilding, IMeterDeviceBillingInfo, ICompamyLogo } from './../interfaces';
import { Injectable, OnInit, EventEmitter, Output } from '@angular/core';
import { IUserProfile, IMeterDevice, IApartment } from 'src/interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfirmdialogComponent } from './confirmdialog/confirmdialog.component';
import { interval } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { CdialogComponent } from './cdialog/cdialog.component';
import { HeatdetailComponent } from './heatdetail/heatdetail.component';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
    providedIn: 'root'
})
export class DataserviceService {

    connected = false;
    socket: any;
    token: string;
    public currentUser: ISysUser;
    userLoggedIn: boolean;
    random: number;
    currentRoot: string;
    currentAera = 0;
    currentApartament: IApartment;
    currentBuilding: IBuilding;
    dictionary: IDictionary[];

    radiators: IRadiatorParameter[];
    companies: ICompany[] = [];
    lastBuildingSearch = '';
    famylies = ['Qundis', 'Caleffi'];
    colors = ['tomato', 'lightblue', 'burlywood', 'brown', 'darkcyan', 'magenta', 'plum', 'grey', 'cornflowerblue', 'gold'];
    monthNamesIt = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
    monthNamesItFull = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    probes = ['k75_2f_205', 'K75_2f_205_SR', 'sontexKC1', 'sontexKC2', 'sontexKCSR'];
    mediumAreas = [
        {
            medium: 8, label: 'Contabilizzatori'
        },
        { medium: 7, label: 'Acqua fredda sanitaria' },
        { medium: 6, label: 'Acqua calda sanitaria' },
        { medium: 10, label: 'Raffrescamento' },
        { medium: 5, label: 'Vapore' },
        { medium: 4, label: 'Riscaldamento' },
    ];

    roles = [
        { value: 0, viewValue: 'Inattivo' },
        { value: 1, viewValue: 'Utente' },
        { value: 2, viewValue: 'Amm. Stabile' },
        { value: 3, viewValue: 'Company Admin' },
        { value: 4, viewValue: 'Global Admin' }
    ];
    constructor(private http: HttpClient, public dialog: MatDialog, private router: Router, public toastr: ToastrService, private clipboard: Clipboard) {
        const token = localStorage.getItem('token');
        this.token = token;
        this.whoAmI();
    }

    getMediumName(medium: number) {

        const med = this.mediumAreas.find(x => x.medium === medium);
        if (med) {
            return med.label;
        }
        return medium.toString();
    }

    compareRadiatorParametersById(f1: IRadiatorParameter, f2: IRadiatorParameter): boolean {
        return f1 && f2 && f1.radiatorParameterId === f2.radiatorParameterId;
    }

    getCSVWEBillingBuilding(item: IBuildingBillingInfo) {
        return this.http.post('/api/billings/we', item, this.getHeader());
    }

    copy(text: string) {
        this.clipboard.copy(text);
        this.toastr.info("Codice copiato negli appunti");
    }

    compareStat(s1: IMeterDeviceStats, s2: IMeterDeviceStats, isAsc: boolean) {
        if (s1.yy < s2.yy) {
            return isAsc ? -1 : 1;
        }
        if (s1.yy > s2.yy) {
            return isAsc ? 1 : -1;
        }
        if (s1.mm < s2.mm) {
            return isAsc ? -1 : 1;
        }
        if (s1.mm > s2.mm) {
            return isAsc ? 1 : -1;
        }

        return 0;
    }

    compare(a: number | string, b: number | string, isAsc: string) {
        return (a < b ? -1 : 1) * (isAsc === 'asc' ? 1 : -1);
    }

    compareInt(a: number, b: number, isAsc: string) {
        return (a < b ? -1 : 1) * (isAsc === 'asc' ? 1 : -1);
    }

    compareDate(a: Date, b: Date, isAsc: string) {
        return (a.getTime() < b.getTime() ? -1 : 1) * (isAsc === 'asc' ? 1 : -1);
    }

    /*  testDialog() {
          const dialogRef = this.dialog.open(CdialogComponent, {
              data: { text: 'aa', buttons: [] }
          });
  
          dialogRef.afterClosed().subscribe((result) => {
              //  // console.log(result);
  
          });
      }*/
    confirm(title: string, buttons: string[]) {

        return new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(ConfirmdialogComponent, {
                data: {
                    text: title, buttons
                }, width: '500px',
                height: '200px'
            });

            dialogRef.afterClosed().subscribe((result) => {
                //  // console.log(result);
                resolve(result);
            });
        });
    }

    getBillStartDate(date: number) {
        const month = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'];

        return month[date % 12] + ' ' + (Math.trunc(date / 12) + 2000);
    }

    getBillStartDateFull(date: number) {
        const month = ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];

        return month[date % 12] + ' ' + (Math.trunc(date / 12) + 2000);
    }

    startWebsocket() {
        if (!this.connected) {
            // console.log('Start webSocket');
            this.socket.subscribe(
                (message) => {
                    this.connected = true;
                },
                (error) => {
                    console.error(error); // handle errors
                    this.connected = false;
                }
            );
        } else {
            // console.log('websocket is connected');
        }
    }

    strToBuffer(str) {
        const arrayBuffer = new ArrayBuffer(str.length * 1);
        const newUint = new Uint8Array(arrayBuffer);
        newUint.forEach((_, i) => {
            newUint[i] = str.charCodeAt(i);
        });
        return newUint;
    }

    decodeUpdates(message: any) { }

    newGuid() {
        return 'xxxxxxxx-xxxx-'.replace(/[xy]/g, (c) => {
            // tslint:disable-next-line:no-bitwise
            const r = (Math.random() * 16) | 0;
            // tslint:disable-next-line:no-bitwise
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    getUserHeader() {
        if (this.token) {
            const token = this.token;

            const headersObject = new HttpHeaders({
                Authorization: 'Bearer ' + token
            });

            const httpOptions = {
                headers: headersObject
            };
            return httpOptions;
        } else {
            return {};
        }
    }

    getHeader() {
        if (this.token) {
            const token = this.token;

            const headersObject = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            });

            const httpOptions = {
                headers: headersObject
            };
            return httpOptions;
        } else {
            return {};
        }
    }

    getPDFHeader() {
        if (this.token) {
            const token = this.token;

            const headersObject = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                Accept: 'application/pdf'
            });

            const httpOptions = {
                headers: headersObject,
                responseType: 'blob'
            };
            return httpOptions;
        } else {
            return {};
        }
    }

    getTextHeader() {
        if (this.token) {
            const token = this.token;

            const headersObject = new HttpHeaders({
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token
            });

            const httpOptions = {
                headers: headersObject,
                responseType: 'text'
            };
            return httpOptions;
        } else {
            return {};
        }
    }

    addUser(selectedUser: ISysUser) {
        return this.http.post('/api/users', selectedUser, this.getHeader());
    }

    login(user: ISysUser, remembre: boolean, errorCb) {
        this.http.post('/api/users/login', user).subscribe(
            (result) => {
                // console.log('result: ', result);
                this.token = (result as any).token;
                if (remembre) {
                    localStorage.setItem('token', this.token);
                } else {
                    localStorage.setItem('token', '');

                }
                this.whoAmI();

                this.router.navigate(['/']);
            },
            (error) => {
                console.error(error);
                // console.log(error);
                this.currentUser = null;
                this.userLoggedIn = false;
                this.toastr.error('error', 'Login error');
                errorCb(error);
            }
        );
    }

    logout() {
        this.token = undefined;
        localStorage.setItem('token', this.token);
        this.router.navigate(['/login']);
        this.currentUser = undefined;
    }

    goHome() {
        this.router.navigate(['/']);
    }



    async whoAmI() {
        try {
            const user = await this.http.get<ISysUser>('/api/users/whoami', this.getHeader()).toPromise();
            if (user) {
                this.currentUser = user as ISysUser;
                this.toastr.success('Login', 'Benvenuto ' + this.currentUser.firstName);
                if (this.currentUser.role > 2) {
                    this.getCompanies().subscribe((data) => {
                        this.companies = data as ICompany[];
                    });
                }
                const seconds = interval(5000);

            }
            else {
                this.currentUser = undefined;
                this.testLogged();
            }
        } catch {
            this.currentUser = undefined;
            this.testLogged();
        }
        /*
        
                this.http.get('/api/users/whoami', this.getHeader()).subscribe(
                    (user) => {
                        this.currentUser = user as ISysUser;
                        this.toastr.success('Login', 'Benvenuto ' + this.currentUser.firstName);
                        if (this.currentUser.role === 4) {
                            this.getCompanies().subscribe((data) => {
                                this.companies = data as ICompany[];
                            });
                        }
                        const seconds = interval(5000);
                    },
                    (error) => {
                        this.currentUser = undefined;
                        this.testLogged();
                    }
                );*/
    }

    async whoAmIBlocking() {
        this.currentUser = (await this.http.get('/api/users/whoami', this.getHeader()).toPromise()) as ISysUser;
    }

    getPermission(logged: boolean, role: number) {
        if (logged === true) {
            if (this.currentUser === undefined || this.currentUser === null) {
                return false;
            }

            return this.currentUser.role >= role;
        }
        if (this.currentUser === undefined) {
            return true;
        }
        return false;
    }

    getAuth(logged: boolean, role: number, area: number) {
        if (!this.getPermission(logged, role)) {
            return { display: 'none' };
        }
        return {};
    }

    testLogged() {
        // console.log('VVVVVV');
        if (undefined === this.currentUser && this.router.url !== '/login' && this.router.url !== '/signup' && this.router.url !== '/verify') {
            this.router.navigate(['/login']);
        }
    }


    postCreateConcentratorLabel(labels: number[]) {
        this.http.post('/api/concentrators/labels', labels, this.getPDFHeader()).subscribe((data) => {
            const file = new Blob([data as ArrayBuffer], { type: 'application/pdf' });
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
        });
    }

    setConcentratorStatus(ids: number[], id: number) {
        return this.http.post('/api/concentrators/status/' + id, ids, this.getHeader());
    }

    setPeriod(ids: number[], id: number) {
        return this.http.post('/api/concentrators/period/' + id, ids, this.getHeader());
    }

    setCompany(ids: number[], id: number) {
        return this.http.post('/api/concentrators/company/' + id, ids, this.getHeader());
    }

    setUtil(command: string) {
        return this.http.get('/api/inexp/' + command, this.getHeader());
    }
    getUsers(filter: string, id: number) {
        if (filter) {
            return this.http.get('/api/users/' + filter + '/' + id, this.getHeader());
        }
        return this.http.get('/api/users', this.getHeader());
    }

    deleteUser(user: ISysUser) {
        return this.http.delete('/api/users/' + user.sysUserId, this.getHeader());
    }

    verifyUser(code: string) {
        return this.http.get('/api/users/verify/' + code);
    }

    saveMeterName(device: IMeterDevice) {
        return this.http.put('/api/meters/name/' + device.meterDeviceId, device, this.getHeader());
    }

    findFilterDevices(serial: string) {
        return this.http.get('/api/meters/search/' + serial, this.getHeader());
    }

    deleteBuilding(item: IBuilding) {
        return this.http.delete('/api/buildings/' + item.buildingId, this.getHeader());
    }

    getBuildingCoverage(item: number) {
        return this.http.get('/api/buildings/coverage/' + item, this.getHeader());

    }

    getBuildingConcentratorCoverage(item: number, conc: number) {
        return this.http.get('/api/buildings/coverage/' + item + '/' + conc, this.getHeader());

    }

    getExportBuilding(item: IBuilding, date: number) {
        return this.http.get('/api/buildings/export/' + item.buildingId + '/' + date, this.getHeader());

    }

    getExportBuildingFrom(item: IBuilding) {
        return this.http.get('/api/buildings/exportFrom/' + item.buildingId, this.getHeader());

    }

    getExportBuildingData(item: IBuilding) {
        return this.http.get('/api/buildings/export/' + item.buildingId, this.getHeader());

    }

    getBuildings() {
        return this.http.get('/api/buildings', this.getHeader());
    }

    getBuildingNames() {
        return this.http.get('/api/buildings/names', this.getHeader());
    }

    getBuilding(id: number) {
        return this.http.get('/api/buildings/' + id, this.getHeader());
    }

    getFullBuilding(id: number) {
        return this.http.get('/api/buildings/full/' + id, this.getHeader());
    }

    getBillBuilding(did: string, id: number, st: number, en: number) {
        return this.http.get('/api/buildings/bill/' + did + '/' + st + '/' + en + '/' + id, this.getHeader());
    }

    getBillBuildingList(id: number) {
        return this.http.get('/api/billings/building/' + id, this.getHeader());
    }

    getBillDateBuildingList(id: number) {
        return this.http.get('/api/billings/building/to/' + id, this.getHeader());
    }

    deleteBilling(id: number) {
        return this.http.delete('/api/billings/' + id, this.getHeader());
    }

    postCalcBillBuilding(item: IBillingBuilding) {
        return this.http.post('/api/billings/building', item, this.getHeader());
    }

    postSummaryBillingBuilding(item: IBillingBuilding) {
        return this.http.post('/api/billings/summary', item, this.getHeader());
    }

    getRadiators() {
        return this.http.get('/api/radiators', this.getHeader());
    }

    getMeterHistory(data: IMeterDevice) {
        return this.http.get('/api/meters/history/' + data.meterDeviceId, this.getHeader());
    }

    deleteRadiator(id: number) {
        return this.http.delete('/api/radiators/' + id, this.getHeader());
    }

    saveRadiator(radiators: IRadiatorParameter) {
        return this.http.put('/api/radiators', radiators, this.getHeader());
    }

    addRadiator(radiators: IRadiatorParameter) {
        return this.http.post('/api/radiators', radiators, this.getHeader());
    }

    getApartmentBilling(billing: IBuildingBillingInfo, id: number) {
        return this.http.post('/api/billings/apartment/' + id, billing, this.getPDFHeader());

    }

    postBuildingBillingReport(id: number, billing: IBuildingBillingInfo) {
        return this.http.post('/api/billings/building/generate/' + id, billing, this.getPDFHeader());
    }

    getBuildingcode(id: number) {
        this.http.get('/api/buildings/getcodes/' + id, this.getPDFHeader()).subscribe((data) => {
            const file = new Blob([data as ArrayBuffer], { type: 'application/pdf' });
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
        });
    }

    recalcK(dev: IMeterDevice) {
        return this.http.post('api/meters/ck', dev, this.getHeader());
    }

    getMillsCheck(id: number) {
        return this.http.get('api/buildings/checkmills/' + id, this.getHeader());
    }

    getConcentrators() {
        return this.http.get('/api/concentrators', this.getHeader());
    }

    getConcentrator(id: number) {
        return this.http.get('/api/concentrators/' + id, this.getHeader());
    }

    getConcentratorLogs(currentId: string) {
        // console.log('get logs');
        return this.http.get('/api/concentrators/logs/' + currentId, this.getHeader());
    }

    updateProfile(profile: IUserProfile) {
        this.http.post('/api/users/profile', profile, this.getHeader()).subscribe(
            () => {
                this.toastr.success('Aggiornamento utente eseguito');
            },
            () => {
                this.toastr.error('Aggiornamento utente fallito');
            }
        );
    }

    addBuildings(building: IBuilding) {
        return this.http.post('/api/buildings', building, this.getHeader());
    }

    saveBuildings(building: IBuilding) {
        return this.http.put('/api/buildings/' + building.buildingId, building, this.getHeader());
    }

    getCompanies() {
        return this.http.get('/api/companies/', this.getHeader());
    }

    addCompanies(item: ICompany) {
        return this.http.post('/api/companies/', item, this.getHeader());
    }

    updateCompanies(item: ICompany) {
        return this.http.put('/api/companies/' + item.companyId, item, this.getHeader());
    }

    deleteCompanies(item: ICompany) {
        return this.http.delete('/api/companies/' + item.companyId, this.getHeader());
    }

    postCompanyAddImage(id: number, image: ICompamyLogo, b64: string) {
        return this.http.post('/api/companies/images/' + id, { data: b64, info: image }, this.getHeader());
    }

    deleteCompanyImage(id: number, image: string) {
        return this.http.delete('/api/companies/images/' + id + '/' + image, this.getHeader());
    }

    generateStats(apa: IApartment) {
        this.http.get('/api/apartments/restats/' + apa.apartmentId, this.getHeader()).subscribe(
            () => {
                this.toastr.success('Operazione eseguita');
            },
            () => {
                this.toastr.error('Operazione fallita');
            }
        );
    }

    getRole(role: number): string {
        return this.roles.find((x) => x.value === role).viewValue;
    }

    getRandom(randLen: number) {
        const strin = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890X';
        let strout = '';
        for (let i = 0; i < randLen; i++) {
            strout += strin.charAt(Math.random() * strin.length);
        }
        return strout;
    }

    getRandomNumber(randLen: number) {
        const strin = '1234567890';
        let strout = '';
        for (let i = 0; i < randLen; i++) {
            strout += strin.charAt(Math.random() * strin.length);
        }
        return strout;
    }

    getSerial(element: IMeterDevice) {
        return element.serial;
    }

    getSerialOK(element: IMeterDevice, item: IBuilding | IApartment) {
        if (element) {
            switch (element.family) {
                case 0:
                    return element.serial.trim().length !== 8;
                case 1:
                    return element.serial.trim().length !== 7 && element.serial.trim().length !== 6;

            }
        }

        return false;
    }


    getStringOrNull(item) {
        if (item) {
            return item;
        }
        return '--';
    }

    getManu(element: IMeterDevice) {
        switch (element.manufacturer) {
            case 17555:
                return 'QDS';
            case 25906:
                return 'LSE';
            default:
                return element.manufacturer;
        }
    }

    getMedium(element: IMeterDevice) {

        if (element.mediumArea === 0) {
            return this.getMediumName(element.medium);
        }
        return this.getMediumName(element.mediumArea);

    }

    getBulletStyle(element: IMeterDevice) {
        if ((element.status & 0xff) === 0) {
            return { backgroundColor: 'green' };
        }
        if ((element.status & 0xff) === 120) {
            return { backgroundColor: 'orange' };
        }
        return { backgroundColor: 'red' };
    }

    array_move(arr: Array<any>, oldIndex: number, newIndex: number) {
        if (newIndex >= arr.length) {
            let k = newIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        // console.log('Arr: ', arr);
        return arr; // for testing
    }

    getBattVoltage(element: IConcentrator) {
        return (100 - ((Math.max(0, element.vbatt - 60)) / 340) * 100).toFixed(0);
    }

    getMeters(id, src: string) {
        return this.http.get('/api/meters/' + src + '/' + id, this.getHeader());
    }

    getMeterStats() {
        return this.http.get('/api/meters/stats', this.getHeader());
    }

    deleteMeters(id: number, src: string) {
        return this.http.delete('/api/meters/' + src + '/' + id, this.getHeader());
    }

    //fastcalc/{id}
    recalcBillingStat(device: IMeterDeviceBillingInfo) {
        return this.http.post('/api/meters/fastcalc/' + device.meterDeviceId, device, this.getHeader());
    }

    getMeterDetail(id: number) {
        return this.http.get('/api/meters/' + id, this.getHeader());
    }
    saveMeter(item: IMeterDevice) {
        return this.http.put('/api/meters/' + item.meterDeviceId, item, this.getHeader());
    }

    cloneMeterDetail(item: IMeterDevice) {
        return this.http.put('/api/meters/clone/' + item.meterDeviceId, item, this.getHeader());
    }

    getApartmets(id: number) {
        return this.http.get('/api/apartments/list/' + id, this.getHeader());
    }

    getApartmet(id: number) {
        return this.http.get('/api/apartments/' + id, this.getHeader());
    }

    getApartmetStats(id: number) {
        return this.http.get('/api/apartments/stats/' + id, this.getHeader());
    }

    addApartment(item: IApartment) {
        return this.http.post('/api/apartments', item, this.getHeader());
    }

    saveApartment(item: IApartment) {
        return this.http.put('/api/apartments/' + item.apartmentId, item, this.getHeader());
    }

    addDeviceToApartment(item: IMeterDevice, id: number) {
        return this.http.post('/api/apartments/adddevice/' + id, item, this.getHeader());
    }

    saveConcentrator(item: IConcentrator) {
        return this.http.put('/api/concentrators/' + item.concentratorId, item, this.getHeader());
    }

    findDevice(str: string) {
        return this.http.get('/api/meters/find/' + str, this.getHeader());
    }

    deleteDeviceStats(buildingId: number) {
        return this.http.get('/api/meters/cleanstat/' + buildingId, this.getHeader());
    }

    updateUser(user: ISysUser) {
        return this.http.put('/api/users/' + user.sysUserId, user, this.getHeader());
    }

    verifyCode(code) {
        return this.http.get('/api/users/vcode/' + code, this.getHeader());
    }

    recoverUser(user: ISysUser) {
        return this.http.post('/api/users/reqpass', user, this.getHeader());
    }

    getConfirmationCode(newcode: string) {
        return this.http.get('/api/users/confcode/' + newcode, this.getHeader());
    }

    addConfirmationCode(newcode: string) {
        return this.http.get('/api/users/addcode/' + newcode, this.getHeader());
    }

    uploadFile(target: string, data: FormData) {
        return this.http.post('/api/inexp/' + target, data, this.getUserHeader());
    }

    cleanDB() {
        return this.http.get('/api/inexp/clean/4810', this.getHeader());
    }

    resendConcentratorLog(log: IConcentratorLog) {
        return this.http.post('/api/concentrators/logs/resend', log, this.getUserHeader());

    }

    getDictionary() {
        this.http.get('/api/users/dictionaries', this.getHeader()).subscribe((data) => {
            this.dictionary = data as IDictionary[];
        });
    }

    reCompany(item: ICompany) {
        return this.http.get('/api/inexp/recompany/' + item.companyId, this.getHeader());
    }

    editLabel(title: string, label: string, value: string) {
        return new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(EditlabelComponent, {
                data: { title, label, value }
            });

            dialogRef.afterClosed().subscribe((result) => {
                // console.log(result);
                if (result == null) {
                    reject();
                } else {
                    resolve(result);
                }
            });
        });
    }

    downloadFile(data: any) {
        const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
        const header = Object.keys(data[0]);
        const csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        const csvArray = csv.join('\r\n');

        const blob = new Blob([csvArray], { type: 'text/csv' });
        saveAs(blob, 'myFile.csv');
    }

    compareByCompanyId(f1: ICompany, f2: ICompany): boolean {
        return f1 && f2 && f1.companyId === f2.companyId;
    }

    getDecValue(device: IMeterDevice | IMeterDeviceBillingInfo, value) {

        return this.getMediumValue(device.medium, value);
    }

    getMediumValue(medium: number, value) {
        switch (medium) {
            case 8: return value.toFixed(0);
            case 4: return (value / 10).toFixed(1).replace('.', ',');
            case 7: return (value / 1000).toFixed(3).replace('.', ',');
            case 6: return (value / 1000).toFixed(3).replace('.', ',');
        }
        return value;
    }

    setMediumValue(medium: number, val: string) {

        const value = val.toString().replace(',', '.');
        switch (medium) {
            case 8: return parseInt(value);
            case 4: return parseFloat(value) * 10;
            case 7: return parseFloat(value) * 1000;
            case 6: return parseFloat(value) * 1000;
        }
        return parseInt(value);
    }

    getDecUnit(device: IMeterDevice) {
        return this.getMediumUnit(device.medium);
    }

    getMediumUnit(medium: number) {
        switch (medium) {
            case 8: return 'u';
            case 4: return 'kWh';
            case 7: return 'm³';
            case 6: return 'm³'
        }
        return '';
    }
}
