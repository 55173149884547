@if (progress) {
<app-progressspinner></app-progressspinner>
}
@if (building) {
<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/billing.png" />
        <div>
            <h2 class="tabtitle">Lista Bollettazione <span class="titlemark">{{ building.name }}</span></h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>
    <div class="formcontainer">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>Num.</th>
                <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
            </ng-container>
            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef>Riferimento periodo</th>
                <td mat-cell *matCellDef="let element">{{ dataservice.getBillStartDate(element.startDate) }} - {{
                    dataservice.getBillStartDate(element.endDate) }}</td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef>Data creazione</th>
                <td mat-cell *matCellDef="let element">{{ element.created | date : 'dd MMM yyy HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="lastmodify">
                <th mat-header-cell *matHeaderCellDef>Ultima modifica</th>
                <td mat-cell *matCellDef="let element">{{ element.lastmodify | date : 'dd MMM yyy HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="filters">
                <th mat-header-cell *matHeaderCellDef>Elementi</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon [class]="getClass(8, element)" matTooltip="Ripartitori calore">luggage</mat-icon>
                    <mat-icon [class]="getClass(6, element)" matTooltip="Acqua calda sanitaria">water_drop</mat-icon>
                    <mat-icon [class]="getClass(7, element)" matTooltip="Acqua fredda sanitaria">water_drop</mat-icon>
                    <mat-icon [class]="getClass(4, element)" matTooltip="Conta energia">energy_savings_leaf</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="published">
                <th mat-header-cell *matHeaderCellDef>Pubblicato</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="!element.draft">{{ element.lastmodify | date : 'dd MMM yyy HH:mm' }}</span><span
                        *ngIf="element.draft">-</span>
                </td>
            </ng-container>


            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Titolo</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.title }}
                </td>
            </ng-container>


            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Stato</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.draft ? 'DRAFT' : 'PUBBLICATO' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <a [href]="'/billing/' + element.billingId + '/0/0/' + currentId"
                        mat-icon-button><mat-icon>edit</mat-icon></a>
                    <button mat-icon-button (click)="erase(element)"><mat-icon>delete_forever</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="getColumns()"></tr>
            <tr mat-row *matRowDef="let row; columns: getColumns()"></tr>

            <!--<tr mat-footer-row *matFooterRowDef="getColumns()"></tr>-->
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Seleziona pagina">
        </mat-paginator>
    </div>
    <div class="newBill">
        <div> <mat-form-field>
                <mat-label>Inizio periodo</mat-label>
                <mat-select [(ngModel)]="referenceStart">
                    @for (item of monts; track $index) {
                    <mat-option [value]="item">
                        {{ dataservice.getBillStartDate(item) }}
                    </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="field-250-width">
                <mat-label>Fine periodo</mat-label>
                <mat-select [(ngModel)]="referenceEnd" (selectionChange)="change($event)">
                    @for (item of billablesDate.billables ; track $index) {
                    <mat-option [value]="item.index" [class]="getClassBillable(item)">
                        {{ dataservice.getBillStartDate(item.index) }} [{{item.billable}}&nbsp;di&nbsp;{{billablesDate.count}}]
                    </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <a [disabled]="!canAdd()" [href]="'/billing/new/'+referenceStart+'/'+referenceEnd+'/' + currentId"
                mat-raised-button>Crea nuova bolletta</a>
        </div>
    </div>
</div>
}