<app-progressspinner *ngIf="progress"></app-progressspinner>

<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/buildings.svg" />
        <div>
            <h2 class="tabtitle">
                Stabile [<b>{{ currentItem?.name }}</b>]
            </h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>

    <div *ngIf="currentItem" class="formcontainer">
        <div>
            <a href="/apartments/{{ currentItem.buildingId }}" mat-icon-button matTooltip="Unità abitative"><mat-icon
                    [matBadge]="currentItem.apartmentsCount">apartment</mat-icon></a>

            <a mat-icon-button href="/devices/building/{{ currentItem.buildingId }}"
                matTooltip="Contabilizzatori"><mat-icon
                    [matBadge]="currentItem.metersCount">speaker_phone</mat-icon></a>
            <button mat-icon-button (click)="showuser(currentItem, false)"
                matTooltip="Utenti"><mat-icon>people_alt</mat-icon></button>
            <button mat-icon-button (click)="showuser(currentItem, true)" matTooltip="Amministratori"><mat-icon
                    color="warn">people_alt</mat-icon></button>
            <button mat-icon-button (click)="delete(currentItem)" matTooltip="Elimina stabile"><mat-icon
                    color="warn">delete_forever</mat-icon></button>
            <a href="/billings/{{ currentItem.buildingId }}" mat-icon-button matTooltip="Bollettazione"><mat-icon
                    color="primary">euro</mat-icon></a>
        </div>
        <mat-form-field class="field-full-width">
            <mat-label>Nome</mat-label>
            <input matInput placeholder="Nome" [(ngModel)]="currentItem.name" />
        </mat-form-field>

        <mat-form-field class="field field__dug">
            <mat-label>Dug</mat-label>

            <input matInput placeholder="Dug" [(ngModel)]="currentItem.dug" />
        </mat-form-field>

        <mat-form-field class="field field__address">
            <mat-label>Indirizzo</mat-label>
            <input matInput placeholder="Indirizzo" [(ngModel)]="currentItem.address" />
        </mat-form-field>

        <mat-form-field class="field field__number">
            <mat-label>num</mat-label>
            <input matInput placeholder="num" type="number" [(ngModel)]="currentItem.number" />
        </mat-form-field>

        <mat-form-field class="field field__cap">
            <mat-label>CAP</mat-label>
            <input matInput placeholder="CAP" [(ngModel)]="currentItem.zip" />
        </mat-form-field>
        <mat-form-field class="field field__prov">
            <mat-label>Provincia</mat-label>

            <input matInput placeholder="Provincia" [(ngModel)]="currentItem.province" />
        </mat-form-field>
        <mat-form-field class="field field__city">
            <mat-label>Città</mat-label>

            <input matInput placeholder="Città" [(ngModel)]="currentItem.city" />
        </mat-form-field>
        <mat-form-field class="field field__country">
            <mat-label>Nazione</mat-label>

            <input matInput placeholder="Nazione" [(ngModel)]="currentItem.country" />
        </mat-form-field>

        <mat-form-field class="field-full-width">
            <mat-label>Note</mat-label>
            <input matInput placeholder="Note" [(ngModel)]="currentItem.note" />
        </mat-form-field>

        <mat-form-field class="field field__bnumber">
            <mat-label># stabile</mat-label>
            <input matInput placeholder="# stabile" type="number" [(ngModel)]="currentItem.buildingNumber" />
        </mat-form-field>

        <mat-form-field class="field field__orderNo">
            <mat-label>Commessa</mat-label>

            <input matInput placeholder="Commessa" [(ngModel)]="currentItem.orderNo" />
        </mat-form-field>

        <mat-form-field class="field field__mills">
            <mat-label>% volontaria</mat-label>

            <input matInput placeholder="% volontaria" [(ngModel)]="currentItem.voluntaryPerc" />
        </mat-form-field>

        <mat-form-field class="field field__mills">
            <mat-label>% involontaria</mat-label>

            <input matInput placeholder="% involontaria" [(ngModel)]="currentItem.involuntaryPerc" />
        </mat-form-field>

        <mat-form-field class="field field__mills">
            <mat-label>Tipo dispositivo</mat-label>
            <input matInput placeholder="Tipo dispositivo" [(ngModel)]="currentItem.heatDeviceType" />
        </mat-form-field>




        <mat-form-field class="field">
            <mat-label>Gestore</mat-label>
            <mat-select [(ngModel)]="currentItem.company" [compareWith]="dataservice.compareByCompanyId">
                <mat-option *ngFor="let company of dataservice.companies" [value]="company">{{ company.name
                    }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="field">
            <mat-label>Inizio contabilizzazione</mat-label>
            <mat-select [(ngModel)]="currentItem.billStart">
                @for (mm of dataservice.monthNamesItFull; track $index; let idx = $index) {
                <mat-option [value]="idx">{{ mm }}</mat-option>
                }
            </mat-select>
        </mat-form-field>


        <div class="metClear">
            <mat-slide-toggle [(ngModel)]="currentItem.metersClearValue">Ripartitori in chiaro</mat-slide-toggle>
        </div>

        <div>
            <button mat-fab [color]="currentItem.useMills === 0 ? 'accent' : 'primary'"
                (click)="currentItem.useMills = 0">
                <mat-icon>{{ getStatusIcon(0) ? 'check' : 'report' }}</mat-icon>
            </button>
            <mat-form-field class="field field__mills">
                <mat-label>Millesimi Energia</mat-label>
                <input matInput placeholder="Millesimi" [(ngModel)]="millsUserEnergy" />
            </mat-form-field>
            <span class="error_mills" *ngIf="!getStatusIcon(0)">Somma appartamenti: {{ (check.millsUserEnergy).toFixed(4) }}</span>
        </div>
        <div>
            <button mat-fab [color]="currentItem.useMills === 1 ? 'accent' : 'primary'"
                (click)="currentItem.useMills = 1">
                <mat-icon>{{ getStatusIcon(1) ? 'check' : 'report' }}</mat-icon>
            </button>
            <mat-form-field class="field field__mills">
                <mat-label>Millesimi Riscaldamento</mat-label>
                <input matInput placeholder="Millesimi Riscaldamento" [(ngModel)]="millsHeatUser" />
            </mat-form-field>
            <span class="error_mills" *ngIf="!getStatusIcon(1)">Somma appartamenti: {{ (check.millsHeatUser ).toFixed(4) }}</span>
        </div>
        <div>
            <button mat-fab [color]="currentItem.useMills === 2 ? 'accent' : 'primary'"
                (click)="currentItem.useMills = 2">
                <mat-icon>{{ getStatusIcon(2) ? 'check' : 'report' }}</mat-icon>
            </button>
            <mat-form-field class="field field__mills">
                <mat-label>Millesimi Proprietà</mat-label>
                <input matInput placeholder="Millesimi" [(ngModel)]="millsPropertyUser" />
            </mat-form-field>

            <span class="error_mills" *ngIf="!getStatusIcon(2)">Somma appartamenti: {{ (check.millsPropertyUser).toFixed(4) }}</span>
        </div>

        <mat-form-field class="field field__mills">
            <mat-label>Costo lettura</mat-label>
            <input matInput placeholder="Costo lettura" [(ngModel)]="currentItem.buildingReadCost" />
            <span matSuffix class="munit">€</span>
        </mat-form-field>

        <div>
            <mat-form-field>
                <mat-label>Codice</mat-label>

                <input matInput placeholder="Codice" [(ngModel)]="currentItem.code" />
            </mat-form-field>
            <button mat-icon-button (click)="reCode()">
                <mat-icon class="mat-18">restore_page</mat-icon>
            </button>
        </div>
        <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">SALVA</button>
        <button mat-raised-button color="warn" class="ideb" (click)="deleteStat()">ELIMINA STATISTICHE LETTORI</button>
        <button mat-raised-button class="ideb" (click)="updateBuilding()">Aggiona anagrafica appartamenti</button>
    </div>
</div>
<div class="tabcontainer">
    <div>
        <h2 class="tabtitle">Importa file rep</h2>

        <input type="file" class="file-input" (change)="onRadFileSelected($event)" #fileUpload />

        <div class="file-upload">
            {{ fileRadName || 'No file uploaded yet.' }}

            <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
                <mat-icon>attach_file</mat-icon>
            </button>
        </div>
    </div>

    <div>
        <h2 class="tabtitle">Importa file XML</h2>

        <input type="file" class="file-input" (change)="onXMLFileSelected($event)" #fileXMLUpload />

        <div class="file-upload">
            {{ fileRadName || 'No file uploaded yet.' }}

            <button mat-mini-fab color="primary" class="upload-btn" (click)="fileXMLUpload.click()">
                <mat-icon>attach_file</mat-icon>
            </button>
        </div>
    </div>
</div>