<app-progressspinner *ngIf="progress"></app-progressspinner>

<div class="tabcontainer" *ngIf="editedUser !== undefined">
    <h2 class="tabtitle">Profilo {{ editedUser.email }}</h2>
    <hr />

    <cdk-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Dati anagrafici</mat-panel-title>
                <mat-panel-description></mat-panel-description>
                <!--<mat-icon>account_circle</mat-icon>-->
            </mat-expansion-panel-header>
            <div>
                <mat-form-field class="field-full-width">
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Nome" [(ngModel)]="editedUser.firstName" />
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Cognome</mat-label>
                    <input matInput placeholder="Cognome" [(ngModel)]="editedUser.lastName" />
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Email</mat-label>

                    <input matInput type="email" readonly="true" [formControl]="emailFormControl"
                        [errorStateMatcher]="matcher" placeholder="Email" [(ngModel)]="editedUser.email" />
                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        Please enter a valid email address </mat-error>
                    <mat-error *ngIf="emailFormControl.hasError('required')"> Email is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Telefono</mat-label>
                    <input matInput placeholder="Telefono" [(ngModel)]="editedUser.phone" />
                </mat-form-field>

                <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveProfile()">SALVA</button>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Cambia password</mat-panel-title>
                <mat-panel-description></mat-panel-description>
                <!--<mat-icon>account_circle</mat-icon>-->
            </mat-expansion-panel-header>
            <div>
                <mat-form-field class="field-full-width">
                    <mat-label>Password corrente</mat-label>
                    <input matInput placeholder="Password corrente" type="password"
                        [(ngModel)]="editedUser.oldpassword" />
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Nuova password</mat-label>

                    <input matInput placeholder="Nuova password" type="password" [(ngModel)]="editedUser.newpassword" />
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Conferma password</mat-label>

                    <input matInput placeholder="Conferma nuova password" type="password"
                        [(ngModel)]="confirmPassword" />
                </mat-form-field>
                <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveProfile()">SALVA</button>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="dataservice.currentUser.role<3">
            <mat-expansion-panel-header>
                <mat-panel-title>Aggiungi unità immobiliare</mat-panel-title>
                <mat-panel-description></mat-panel-description>
                <!--<mat-icon>account_circle</mat-icon>-->
            </mat-expansion-panel-header>
            <div>
                <mat-form-field>
                    <mat-label>Codice</mat-label>

                    <input matInput placeholder="Codice" [(ngModel)]="newcode" />
                </mat-form-field>
                <button mat-icon-button (click)="confirmCode()">
                    <mat-icon class="mat-18">add</mat-icon>
                </button>
                <div class="confirmeddata" *ngIf="response !== undefined">
                    <div *ngIf="response.response === 0">Stai aggiungendo</div>
                    <div *ngIf="response.response === 1" class="warn"><b>ATTENZIONE</b> sei già un utente di</div>
                    <div *ngIf="response.response === 2" class="warn"><b>ATTENZIONE</b> Nessuna unità immobiliare
                        trovata, controlla il codice inserito</div>
                    <h3 *ngIf="response.apartment !== undefined">
                        Unità immobiliare <b>{{ response.apartment.name }}</b> situato in {{
                        getAddress(response.apartment.building) }}
                    </h3>
                    <h3 *ngIf="response.building !== undefined">
                        <b>Stabile {{ response.building.name }}</b> situato in {{ getAddress(response.building) }}
                    </h3>
                </div>
                <button mat-raised-button *ngIf="validateAddData()" (click)="confirmAddedData()">Conferma</button>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="dataservice.currentUser.role==3">
            <mat-expansion-panel-header>
                <mat-panel-title>Aggiungi concentratore alla società</mat-panel-title>
                <mat-panel-description></mat-panel-description>
                <!--<mat-icon>account_circle</mat-icon>-->
            </mat-expansion-panel-header>
            <div>
                <mat-form-field>
                    <mat-label>Codice</mat-label>

                    <input matInput placeholder="Codice" [(ngModel)]="newcode" />
                </mat-form-field>
                <button mat-raised-button (click)="confirmCode()">
                    <mat-icon class="mat-18">add</mat-icon>Aggiungi
                </button>
                <div class="confirmeddata" *ngIf="response !== undefined">
                    <div *ngIf="response.response === 0">Stai aggiungendo</div>
                    <div *ngIf="response.response === 1" class="warn"><b>ATTENZIONE</b> sei già un utente di</div>
                    <div *ngIf="response.response === 2" class="warn"><b>ATTENZIONE</b> Nessun elemento trovato,
                        controlla il codice inserito</div>
                    <div *ngIf="response.response === 3" class="warn"><b>ATTENZIONE</b> Il concentratore è associato ad
                        una diversa società, contattare l'amministrazione del sistema.</div>
                    <h3 *ngIf="response.conc !== undefined">
                        concentratore <b>{{ response.conc.description }}</b> MAC: {{
                        response.conc.mac }}
                    </h3>

                </div>
                <button mat-raised-button *ngIf="validateAddData()" (click)="confirmAddedData()">Conferma</button>
            </div>
        </mat-expansion-panel>


        <mat-expansion-panel *ngIf="dataservice.currentUser.role==3 && dataservice.currentUser.company">
            <mat-expansion-panel-header>
                <mat-panel-title>Anagrafica società {{dataservice.currentUser.company.name}}</mat-panel-title>
                <mat-panel-description></mat-panel-description>
                <!--<mat-icon>account_circle</mat-icon>-->
            </mat-expansion-panel-header>
            <div>
                <mat-form-field class="field-full-width">
                    <mat-label>Nome società</mat-label>
                    <input readonly="true" matInput placeholder="Nome società"
                        [(ngModel)]=" dataservice.currentUser.company.name" />
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Descrizione</mat-label>
                    <input matInput placeholder="Descrizione"
                        [(ngModel)]=" dataservice.currentUser.company.description" />
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Indirizzo</mat-label>
                    <input matInput placeholder="Indirizzo" [(ngModel)]=" dataservice.currentUser.company.address" />
                </mat-form-field>
                <div class="form-field-flex">


                    <mat-form-field class="field-33-width">
                        <mat-label>Contatto</mat-label>
                        <input matInput placeholder="contatto" [(ngModel)]=" dataservice.currentUser.company.manager" />
                    </mat-form-field>
                    <mat-form-field class="field-33-width">
                        <mat-label>eMail</mat-label>
                        <input matInput placeholder="eMail" [(ngModel)]=" dataservice.currentUser.company.email" />
                    </mat-form-field>
                    <mat-form-field class="field-33-width">
                        <mat-label>Telefono</mat-label>
                        <input matInput placeholder="Telefono" [(ngModel)]=" dataservice.currentUser.company.phone" />
                    </mat-form-field>
                </div>
            </div>
            <button mat-raised-button [disabled]="!validateCompany()" class="ideb"
                (click)="saveCompanyProfile()">SALVA</button>

        </mat-expansion-panel>
        @if (dataservice.currentUser.role>2) {

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Profilo aziendale</mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field class="field">
                <mat-label>Gestore</mat-label>
                <mat-select [(ngModel)]="currentCompany" [compareWith]="dataservice.compareByCompanyId">
                    @for (company of dataservice.companies; track $index) {
                    <mat-option [value]="company">{{ company.name }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <div class="imagethumb">
                @if (currentCompany && currentCompany.logos!=null) {
                @for (item of currentCompany.logos; track $index) {
                <div class="imagethumb__image" [style]="getBk(item.image)">
                    <div class="imagethumb__image__toolbar" >
                        <mat-icon class="imagethumb__image__toolbar__buttons" (click)="deleteLogo(item)">delete</mat-icon>
                        <mat-icon class="imagethumb__image__toolbar__buttons" (click)="editLogo(item)">edit</mat-icon>
                    </div>
                    <div class="imagethumb__image__footer">
                        {{item.name}}
                    </div>
                </div>
                }
                }

            </div>
            <div class="newmage">
                <img id="blah" [src]="imageSrc || 'http://placehold.it/180'" alt="Logo" />
                @if (this.image) {
                <div class="newimagetools">
                    <div>Larghezza: {{image?.width}}</div>
                    <div>Altezza: {{image?.height}}</div>
                    <mat-form-field class="field-full-width">
                        <mat-label>Titolo</mat-label>
                        <input matInput placeholder="Titolo" [(ngModel)]="newImage.name" />
                    </mat-form-field>
                    <mat-form-field class="field-full-width">
                        <mat-label>Informazioni addizionali</mat-label>
                        <input matInput placeholder="Informazioni addizionali" [(ngModel)]="newImage.info" />
                    </mat-form-field>

                    <button mat-raised-button (click)="addImage()">Aggiungi</button>

                </div>
                }
            </div>
            <input type="file" class="file-input" (change)="onRadFileSelected($event)" #fileRadUpload />

            <div class="file-upload">
                {{ fileRadName || 'No file uploaded yet.' }}

                <button mat-mini-fab color="primary" class="upload-btn" (click)="fileRadUpload.click()">
                    <mat-icon>attach_file</mat-icon>
                </button>
            </div>
        </mat-expansion-panel>
        }
    </cdk-accordion>
</div>