import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { IBuilding, ICompamyLogo, ICompany, IUserProfile, IVerifyCode } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { CdkAccordion } from '@angular/cdk/accordion';
import { NgIf } from '@angular/common';
import { ProgressspinnerComponent } from "../progressspinner/progressspinner.component";
import { MatSelectModule } from '@angular/material/select';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatSelectModule, NgIf, CdkAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatError, MatButton, MatIconButton, MatIcon, ProgressspinnerComponent]
})
export class ProfileComponent implements OnInit {
    editedUser: IUserProfile;
    confirmPassword: string;
    newcode = '';
    addedData = '';
    response: IVerifyCode;
    emailFormControl = new FormControl('', [Validators.required, Validators.email]);
    fileRadName = '';
    progress = false;
    imageSrc;
    dataUrl: string;
    newImage: ICompamyLogo = { image: '', info: '', name: '' };

    matcher = new MyErrorStateMatcher();
    image: HTMLImageElement;
    currentCompany: ICompany;
    constructor(public dataservice: DataserviceService) { }

    getBk(url: string) {
        return { backgroundImage: 'url("/api/companies/images/' + url + '")' }
    }

    editLogo(item: ICompamyLogo) {
        this.newImage = { ...item };
    }
    deleteLogo(item: ICompamyLogo) {
        this.dataservice.confirm('Vuoi eliminare l\'immagine?', ['No', 'Si']).then(resp => {
            if (resp === 1) {
                this.dataservice.deleteCompanyImage(this.currentCompany.companyId, item.image).subscribe(() => {
                    this.dataservice.toastr.success('Eseguito');
                    this.dataservice.getCompanies().subscribe((data) => {
                        this.dataservice.companies = data as ICompany[];
                        this.currentCompany = this.dataservice.companies.find(x => x.companyId === this.currentCompany.companyId);
                    });
                }, () => {
                    this.dataservice.toastr.error('Errore');
                })
            }
        })
    }


    ngOnInit() {
        this.editedUser = {
            email: this.dataservice.currentUser.email,
            firstName: this.dataservice.currentUser.firstName,
            lastName: this.dataservice.currentUser.lastName,
            phone: this.dataservice.currentUser.phone,
            oldpassword: '',
            newpassword: ''
        } as IUserProfile;
    }

    onRadFileSelected(event) {
        const file: File = event.target.files[0];
        console.log('qui');

        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                this.imageSrc = reader.result
                this.image = new Image();
                this.image.src = reader.result as string;
                this.image.onload = () => {
                    console.log('Width: ' + this.image.width, 'heigh: ' + this.image.height);
                }

            };

            reader.readAsDataURL(file);

        }
    }

    addImage() {
        const b64 = this.image.src;
        this.dataservice.postCompanyAddImage(this.currentCompany.companyId, this.newImage, b64).subscribe(() => {
            this.dataservice.getCompanies().subscribe((data) => {
                this.dataservice.companies = data as ICompany[];
                this.currentCompany = this.dataservice.companies.find(x => x.companyId === this.currentCompany.companyId);
            });
            this.dataservice.toastr.success('Aggiunta eseguita');
        }, () => {
            this.dataservice.toastr.error('Errore');
        });
    }

    saveProfile() {
        this.dataservice.updateProfile(this.editedUser);
    }

    saveCompanyProfile() {
        this.dataservice.updateCompanies(this.dataservice.currentUser.company).subscribe(() => {
            this.dataservice.toastr.success('Aggiornamento eseguito');
        }, () => {
            this.dataservice.toastr.error('Errore durante l\'aggiornamento');

        });
    }

    validateCompany() {
        if (!this.dataservice.currentUser.company) {
            return false;
        }
        if (this.dataservice.currentUser.company.name.trim() == '') {
            return false;
        }
        return true;
    }

    validate() {
        // console.log('test');
        if (this.editedUser.firstName.trim() === '' || this.editedUser.lastName.trim() === '' || this.editedUser.email.trim() === '') {
            return false;
        }
        if (this.editedUser.oldpassword !== '') {
            if (this.editedUser.newpassword.length < 8) {
                return false;
            }
            if (this.editedUser.newpassword !== this.confirmPassword) {
                return false;
            }
        }

        return true;
    }

    getAddress(item: IBuilding) {
        return item.address + ' ' + item.zip + ' ' + item.city + ' (' + item.province + ') ' + item.country;
    }

    confirmCode() {
        this.dataservice.getConfirmationCode(this.newcode).subscribe((data) => {
            this.response = data as IVerifyCode;
        });
    }
    confirmAddedData() {
        this.dataservice.addConfirmationCode(this.newcode).subscribe((data) => {
            this.dataservice.toastr.success('Struttura aggiunta al profilo');
            this.dataservice.whoAmI();
        });
    }

    validateAddData() {
        if (this.response === undefined) {
            return false;
        }
        return this.response.response === 0;
    }
}
