import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataserviceService } from '../dataservice.service';
import { IApartment, IBuilding } from 'src/interfaces';
import { FormsModule } from '@angular/forms';
import { MatIconAnchor, MatIconButton, MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-addapartment',
  standalone: true,
  imports: [MatFormField, MatLabel, MatInput, FormsModule, MatButton],
  templateUrl: './addapartment.component.html',
  styleUrl: './addapartment.component.scss'
})
export class AddapartmentComponent {


  currentBuilding: IBuilding;
  currentApartment: IApartment = { name: '', number: -1 } as IApartment;
  heatMills: string;
  energyMills: string;
  propertyMills: string;
  constructor(public dialog: MatDialog, public dataservice: DataserviceService, public dialogRef: MatDialogRef<AddapartmentComponent>, @Inject(MAT_DIALOG_DATA) public data: IBuilding) {
    this.currentBuilding = data as IBuilding;
  }

  saveData() {
    this.currentApartment.building = this.currentBuilding;
    this.dataservice.addApartment(this.currentApartment).subscribe((x) => {
      console.log('closing...', x);

      if (x!=null && (x as any).message) {
        this.dataservice.toastr.error((x as any).message);

      } else {
        this.dataservice.toastr.success('Salvataggio eseguito');
        this.dialogRef.close(true);
      }
    }, () => {
      this.dataservice.toastr.error('Errore durante il salvataggio');

    });
  }

  close() {
    this.dialogRef.close();
  }
  validate(): boolean {
    if (this.currentApartment.name.trim() === '') {
      return false;
    }
    if (this.currentApartment.number === -1) {
      return false;
    }
    return true;
  }
}
