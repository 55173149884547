import { IApartment, IMeterDevice } from './../../interfaces';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-adddevices',
    templateUrl: './adddevices.component.html',
    styleUrls: ['./adddevices.component.scss'],
    standalone: true,
    imports: [MatCardModule, FormsModule, MatSelectModule, MatLabel, MatDialogModule, MatFormField, MatInput, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatIconButton, NgIf, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, NgClass, MatPaginator, NgFor, MatButton]
})
export class AdddevicesComponent implements OnInit, AfterViewInit {
    item: IApartment;
    // items: IMeterDevice[];
    items: MatTableDataSource<IMeterDevice>;
    addedItems: IMeterDevice[] = [];
    displayedColumns: string[] = ['position', 'devid', 'devprod', 'action'];
    medium = 8;
    tofind = '';
    newName = '';
    @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor(public dialog: MatDialog, public dataservice: DataserviceService, public dialogRef: MatDialogRef<AdddevicesComponent>, @Inject(MAT_DIALOG_DATA) public data: IApartment) {
        this.item = { ...data };
        for (const item of data.meterDevices) {
            this.addedItems.push(item);
        }
    }

    canAdd() {
        if (this.tofind.length === 8) {
            if (this.items.data.length === 0) {
                return true;
            }
        }
        return false;
    }
    ngAfterViewInit() { }
    onSearchChange(tofind: string) {
        if (tofind.trim().length > 2) {
            this.dataservice.findDevice(tofind.trim()).subscribe((data) => {
                this.items = new MatTableDataSource<IMeterDevice>(data as IMeterDevice[]);
                this.items.paginator = this.paginator;
                this.tofind = tofind;
            });
        }
    }
    ngOnInit(): void {


    }


    getIndex(device: IMeterDevice) {
        return this.addedItems.findIndex((x) => x.meterDeviceId === device.meterDeviceId);
    }

    isSelected(device: IMeterDevice) {
        return this.addedItems.findIndex((x) => x.meterDeviceId === device.meterDeviceId) !== -1;
    }

    addDevice(device: IMeterDevice) {
        const found = this.addedItems.findIndex((x) => x.meterDeviceId === device.meterDeviceId);
        if (found === -1) {
            this.addedItems.push(device);
        } else {
            this.addedItems.splice(found, 1);
        }
    }

    addData() {
        const meter: IMeterDevice = {
            serial: this.tofind,
            medium: this.medium,
            mediumArea: this.medium,
            name: this.newName


        } as IMeterDevice;
        this.dataservice.addDeviceToApartment(meter, this.item.apartmentId).subscribe((data) => {
            this.dataservice.toastr.success('Salvataggio eseguito');
            this.dialogRef.close();

        });
    }

    saveData() {
        this.item.meterDevices = this.addedItems;
        // console.log('Saving data', this.item);
        this.dataservice.saveApartment(this.item).subscribe((data) => {
            this.dataservice.toastr.success('Salvataggio eseguito');
        });
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }
}
