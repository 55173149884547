<app-progressspinner *ngIf="progress"></app-progressspinner>
<div class="tabcontainer">
    <div *ngIf="key !== 'my'" class="sectiontitleicon">
        <img src="/assets/home.png" />
        <div>
            <h2 class="tabtitle">
                Unità abitative stabile <b>{{ currentBuilding?.name }}</b>
            </h2>
            <app-breadcrumbnav [data]="breadcrumb"></app-breadcrumbnav>
        </div>
    </div>
    <div *ngIf="currentBuilding" class="buildingaddress">{{ currentBuilding.dug }} {{ currentBuilding.address }} {{
        currentBuilding.number }} {{ currentBuilding.zip }} {{ currentBuilding.city }} ({{ currentBuilding.province }})
    </div>
    <div class="summary">
        <mat-form-field class="field field__find">
            <mat-label>Cerca</mat-label>
            <input matInput placeholder="Cerca" [(ngModel)]="toFind" (ngModelChange)="find()" />
        </mat-form-field>
        <div class="separator"></div>
        <div class="summary__item" (click)="sel('all')" [ngClass]="{ 'summary__item--sel': showActive }">
            <span class="bullet bullet_green"></span>
            <span>Attivi {{ active }}</span>
        </div>
        @if (this.archived>0) {
        <div class="summary__item" (click)="sel('arc')" [ngClass]="{ 'summary__item--sel': showArchived }">
            <span class="bullet bullet_black"></span>
            <span>Visualizza archiviati {{ archived }}</span>
        </div>
        }
        <button mat-raised-button (click)="defSort()">Ordina</button>
    </div>
    <h2 class="tabtitle" *ngIf="key === 'my'">Le mie unità abitative</h2>
    <div class="apatiles" *ngIf="key === 'my'">
        <app-menutab *ngFor="let item of allItems; let i = index" icon="home" [caption]="item.name" color="white"
            [bkcolor]="dataservice.colors[i % 10]" [link]="'/myapartment/' + item.apartmentId"></app-menutab>
    </div>
    <div *ngIf="key !== 'my'">
        <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8"
            (matSortChange)="SortChange($event)">
            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="number">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Nr</th>
                <td mat-cell *matCellDef="let element">{{ element.number }}</td>
            </ng-container>
            <ng-container matColumnDef="updated">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Aggiornato</th>
                <td mat-cell *matCellDef="let element">
                    @if (element.isArchived) {
                    <mat-icon class="bullet_removed" aria-hidden="false">remove_circle</mat-icon>
                    } @else {
                    <div class="bullet bullet_{{getStatus(element)}}"></div>
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="stair">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Scala</th>
                <td mat-cell *matCellDef="let element">{{ element.staircase }}</td>
            </ng-container>
            <ng-container matColumnDef="floor">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Piano</th>
                <td mat-cell *matCellDef="let element">{{ element.floor }}</td>
            </ng-container>
            <ng-container matColumnDef="phone">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Telefono</th>
                <td mat-cell *matCellDef="let element">{{getPhone(element)}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element"></td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef>Codice</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.code }}
                </td>
            </ng-container>

            <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef>Note</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.note }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">

                    <a mat-icon-button href="/myapartment/{{ element.apartmentId }}"
                        matTooltip="Visualizza Report utente"><mat-icon>visibility</mat-icon></a>
                    <a mat-icon-button href="/devices/apartment/{{ element.apartmentId }}"
                        matTooltip="Contabilizzatori"><mat-icon
                            [matBadge]="element.metersCount">speaker_phone</mat-icon></a>
                    @if (dataservice.currentUser.role>2) {

                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <a mat-icon-button href="/apartment/{{ element.apartmentId }}"
                            matTooltip="Modifica"><mat-icon>edit</mat-icon></a>
                        <a mat-icon-button href="/myapartment/{{ element.apartmentId }}"
                            matTooltip="Visualizza Report utente"><mat-icon>visibility</mat-icon></a>
                        <a mat-icon-button href="/devices/apartment/{{ element.apartmentId }}"
                            matTooltip="Contabilizzatori"><mat-icon
                                [matBadge]="element.metersCount">speaker_phone</mat-icon></a>
                        <button mat-icon-button (click)="showuser(element)"
                            matTooltip="Utenti associati"><mat-icon>people_alt</mat-icon></button>
                    </mat-menu>
                    }
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Seleziona pagina">
        </mat-paginator>
    </div>
    <div class="actions mtop20">
        <button *ngIf="dataservice.getPermission(true, 2)" mat-raised-button (click)="addNew()">Aggiungi unità abitativa
            nello stabile</button>

        <button mat-raised-button (click)="geCodes(currentBuilding.buildingId)">Genera codici accesso</button>
        <button mat-raised-button (click)="export()">Esporta letture</button>
    </div>
</div>