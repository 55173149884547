<div class="tabcontainer">
  <h2 class="tabtitle">Gestori</h2>
  <app-progressspinner *ngIf="progress"></app-progressspinner>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>Num.</th>
      <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>

    </ng-container>

    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef>Contatto</th>
      <td mat-cell *matCellDef="let element">{{ element.manager }}</td>

    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>Code</th>
      <td mat-cell *matCellDef="let element">
        <span class="itemcode">{{ element.code }}</span>
        <button mat-raised-button (click)="regenerateCode(element)">Rigenera</button>
      </td>

    </ng-container>


    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="edit(element)">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button (click)="delete(element)">
          <mat-icon>trash</mat-icon>
        </button>
      </td>

    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  </table>
  <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons aria-label="pagina"> </mat-paginator>
  <button mat-raised-button (click)="addItem()">
    <mat-icon>add</mat-icon> Nuova società
  </button>
</div>