import { ConcentratordetailComponent } from './../concentratordetail/concentratordetail.component';
import { ConcentratoractionComponent } from './../concentratoraction/concentratoraction.component';
import { DataserviceService } from './../dataservice.service';
import { IBreadcrumbData, IConcentrator } from './../../interfaces';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatBadge } from '@angular/material/badge';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatIconAnchor, MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf, NgClass, DatePipe } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'app-concentrators',
    templateUrl: './concentrators.component.html',
    styleUrls: ['./concentrators.component.scss'],
    standalone: true,
    imports: [MatSelect, MatOption, NgIf, ProgressspinnerComponent, BreadcrumbnavComponent, MatCheckbox, MatFormField, MatLabel, MatInput, FormsModule, MatIconButton, MatIcon, NgClass, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatIconAnchor, MatBadge, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, DatePipe, MatButton]
})
export class ConcentratorsComponent implements OnInit {
    //    items: IConcentrator[] = [];
    dataSource: MatTableDataSource<IConcentrator>;
    breadcrumb: IBreadcrumbData[] = [];
    progress = false;
    toFind = '';
    // @ViewChild(MatPaginator) paginator: MatPaginator;

    fNonInst = false;
    fOffline = false;
    fError = false;
    fdError = false;
    fNotUpdated = true;
    fAlive = true;
    farchived = false;

    alive = 0;
    old = 0;
    dead = 0;
    berror = 0;
    derr = 0;
    archiveds = 0;
    notInstalled = 0;
    data: IConcentrator[];
    fall: boolean;
    currentStatus = 0;
    constructor(public dialog: MatDialog, public dataservice: DataserviceService) { }

    ngOnInit(): void {
        this.progress = true;
        this.dataservice.getConcentrators().subscribe((data) => {
            this.progress = false;
            this.data = data as IConcentrator[];
            //   this.dataSource = new MatTableDataSource<IConcentrator>(this.data);
            this.calcAlive();
            this.find();
            //  this.dataSource.paginator = this.paginator;
            this.breadcrumb.push({ label: 'HOME', url: '/' });
            this.breadcrumb.push({ label: 'concentratori', url: 'javascript:;' });
        });
    }

    getSelNumber() {
        let retval = 0;
        for (const conc of this.data) {
            if (conc.selected) {
                retval++;
            }
        }
        return retval;
    }

    getStatus(item: number) {
        switch (item) {
            case 0: return "INSTALLATO";
            case 1: return "ARCHIVIATO";
            case 2: return "COLLAUDO";
            case 3: return "MAGAZZINO";
            case 4: return "CONSEGNATO";
        }
        return "N/A [" + item.toString() + "]";
    }

    doAction() {
        const ids = [];
        for (const conc of this.data) {
            if (conc.selected) {
                ids.push(conc.concentratorId);
            }
        }

        const dialogRef = this.dialog.open(ConcentratoractionComponent, {
            width: '800px',
            height: '600px',
            data: ids
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.ngOnInit();
        });
    }
    /*
        doLabel() {
            const ids = [];
            for (const conc of this.data) {
                if (conc.selected) {
                    ids.push(conc.concentratorId);
                }
            }
            this.dataservice.postCreateConcentratorLabel(ids);
        }
    
    
        doDaily() {
            const ids = [];
            for (const conc of this.data) {
                if (conc.selected) {
                    ids.push(conc.concentratorId);
                }
            }
            this.dataservice.setPeriod(ids, 3).subscribe(() => {
                this.dataservice.toastr.success('Operazione eseguita');
            }, () => {
                this.dataservice.toastr.error('Errore durante l\'esecuzione');
            });
        }
    
        doArchive() {
            const ids = [];
            for (const conc of this.data) {
                if (conc.selected) {
                    ids.push(conc.concentratorId);
                }
            }
            this.dataservice.setArchived(ids).subscribe(() => {
                this.dataservice.toastr.success('Operazione eseguita');
            }, () => {
                this.dataservice.toastr.error('Errore durante l\'esecuzione');
            });
        }*/

    getFilteredDevices() {
        const sorted = this.data.filter((x) => this.applyFilter(x));

        return sorted;
    }


    applyFilter(x: IConcentrator): boolean {
        if (this.currentStatus !== -1 && this.currentStatus != x.status) {
            return false;
        }
        let found = this.fall;
        if (this.fAlive && x.visualStatus == 0) {
            found = true;
        }
        if (this.fNonInst && x.visualStatus == 250) {
            found = true;
        }

        if (this.fNotUpdated && x.visualStatus == 100) {
            found = true;
        }

        if (this.fOffline && x.visualStatus == 200) {
            found = true;
        }
        if (this.farchived && x.visualStatus == 255) {
            found = true;
        }

        if (this.toFind.length > 3) {
            let strFound = false;
            if (x.description && x.description.toLocaleLowerCase().indexOf(this.toFind.toLowerCase()) !== -1) {
                strFound = true;
            }
            if (x.imei.toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                strFound = true;
            }
            if (x.imsi.toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                strFound = true;
            }
            if (x.mac.toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                strFound = true;
            }
            found = found && strFound;
        }
        return found;
    }

    getTotalLen(num: number) {
        if (num === 1000) {
            return '1000+'
        }
        return num.toFixed();
    }

    calcAlive() {
        this.alive = 0;
        this.old = 0;
        this.dead = 0;
        this.berror = 0;
        this.derr = 0;
        this.archiveds = 0;
        this.notInstalled = 0;
        const now = new Date().getTime();
        const nowDt = new Date();
        // nowDt.setMonth(nowDt.getMonth() - 1);
        for (const dev of this.data) {
            if (this.currentStatus === -1 || this.currentStatus === dev.status) {
                /* if (dev.archived) {
                     dev.visualStatus = 255;
                     this.archiveds++;
                 } else */

                if (dev.company == null) {
                    dev.visualStatus = 250;
                    this.notInstalled++;

                }
                else if (this.getDeadStatusVal(dev)) {
                    dev.visualStatus = 200;
                    this.dead++;
                }
                else if (this.getStatusVal(dev)) {
                    dev.visualStatus = 100;
                    this.old++;
                } else {
                    dev.visualStatus = 0;
                    this.alive++;
                }
            }

        }
    }

    getTotalSel() {
        return this.fNotUpdated && this.fOffline && this.fAlive && this.farchived && this.fNonInst
    }

    sel(item: string) {
        switch (item) {
            case 'all':
                this.fNotUpdated = true;
                this.fOffline = true;
                this.fAlive = true;
                this.farchived = true;
                this.fNonInst = true;
                break;
            case 'arch':
                this.farchived = !this.farchived;
                break;

            case 'nu':
                this.fNotUpdated = !this.fNotUpdated;
                break;
            case 'ol':
                this.fOffline = !this.fOffline;
                break;
            case 'al':
                this.fAlive = !this.fAlive;
                break;
            case 'ni':
                this.fNonInst = !this.fNonInst;
                break;
        }
        //  this.fall = !(this.fNotUpdated || this.fOffline || this.fAlive || this.farchived);
        this.find();
    }

    onStatusChange() {
        // console.log("change", this.currentStatus)
        this.fNotUpdated = true;
        this.fOffline = true;
        this.fAlive = true;
        this.farchived = true;
        this.fNonInst = true;
        this.calcAlive();
        this.find();
    }

    find() {
        this.dataSource = new MatTableDataSource<IConcentrator>(this.getFilteredDevices());
        //   this.dataSource.paginator = this.paginator;
        this.dataSource.data = this.getFilteredDevices();
    }

    remFind() {
        this.toFind = '';
        this.find();
    }

    getMac(mac: string) {
        return mac;
    }

    getColumns() {
        if (this.dataservice.currentUser.role === 4) {
            return ['position', 'status', 'description', 'firmware', 'mac', 'imei', 'company', 'batt', 'timing', 'last', 'action'];
        }
        return ['position', 'description', 'firmware', 'mac', 'imei', 'timing', 'batt', 'last', 'action'];
    }

    getBattClass(element: IConcentrator) {
        if (element.vbatt < 160) {
            return 'batt__ok';

        }
        if (element.vbatt < 270) {
            return 'batt__good';
        }
        if (element.vbatt < 320) {
            return 'batt__low';
        }
        return 'batt__warn';
    }

    getStatusTT(element: IConcentrator) {
        return '';
    }

    getStatusLabel(element: IConcentrator) {
        switch (element.visualStatus) {
            case 0: return 'INSTALLATO';
            case 100: return 'NON AGGIORNATO';
            case 200: return 'OFFLINE';
            case 255: return 'ARCHIVIATO';
            case 250: return 'NON INSTALLATO';
        }
        return 'N/A';
    }

    getStatusClass(element: IConcentrator) {
        const now = new Date();
        const old = new Date(element.lastUpdate);
        const diff = (now.getTime() - old.getTime()) / 60000;
        if (diff > element.sleepTimer * 1.5) {
            return { 'background-color': 'red' };
        }
        return { 'background-color': 'green' };

    }

    getStatusVal(element: IConcentrator) {
        const now = new Date();
        /*const old = new Date(element.lastUpdate);
        const diff = (now.getTime() - old.getTime()) / 60000;
        return (diff > element.sleepTimer * 1.5);*/
        console.log("Resp",element.mac,  new Date(element.nextUpdate).getTime() < now.getTime(), element.nextUpdate, now);
        return new Date(element.nextUpdate).getTime() < now.getTime();

    }

    getDeadStatusVal(element: IConcentrator) {
        const now = new Date();
        const old = new Date(element.lastUpdate);
        const diff = (now.getTime() - old.getTime()) / 60000;
        return (diff > 43200);

    }

    getBattIcon(element: IConcentrator) {
        if (element.vbatt > 340) {
            return 'battery_1_bar';
        }
        if (element.vbatt > 300) {
            return 'battery_2_bar';
        }

        if (element.vbatt > 260) {
            return 'battery_3_bar';
        }

        if (element.vbatt > 220) {
            return 'battery_4_bar';
        }
        if (element.vbatt > 150) {
            return 'battery_5_bar';
        }
        if (element.vbatt > 100) {
            return 'battery_6_bar';
        }
        return 'battery_full';
    }

    select(item) { }

    edit(item: IConcentrator) {
        const dialogRef = this.dialog.open(ConcentratordetailComponent, {
            width: '800px',
            data: { ...item }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // console.log(result);
                const data = result as IConcentrator;

                this.dataservice.saveConcentrator(data).subscribe((x) => {
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.ngOnInit();
                });
            }
        });
    }
}
