import { DataserviceService } from './../dataservice.service';
import { IBuilding, ICompany, IMillsCheck, IBreadcrumbData } from './../../interfaces';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserlistdialogComponent } from '../userlistdialog/userlistdialog.component';
import { ActivatedRoute } from '@angular/router';
import { ImportapartmentComponent } from '../importapartment/importapartment.component'
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatBadge } from '@angular/material/badge';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconAnchor, MatIconButton, MatFabButton, MatButton, MatMiniFabButton } from '@angular/material/button';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf, NgFor } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-building',
    templateUrl: './building.component.html',
    styleUrls: ['./building.component.scss'],
    standalone: true,
    imports: [MatSlideToggle, NgIf, ProgressspinnerComponent, BreadcrumbnavComponent, MatIconAnchor, MatTooltip, MatIcon, MatBadge, MatIconButton, MatFormField, MatLabel, MatInput, FormsModule, MatSelect, NgFor, MatOption, MatFabButton, MatSuffix, MatButton, MatMiniFabButton]
})
export class BuildingComponent implements OnInit {
    currentItem: IBuilding;
    millsPropertyUser: number;
    millsUserEnergy: number;
    millsHeatUser: number;
    check: IMillsCheck;
    fileRadName = '';
    currentId = 0;
    progress = false;
    breadcrumb: IBreadcrumbData[] = [];
    constructor(private route: ActivatedRoute, public dataservice: DataserviceService, public dialog: MatDialog) {
        this.route.paramMap.subscribe(async (map) => {
            // console.log('Pmap; ', map.get('id'));
            this.currentId = parseInt(map.get('id'), 10);
            this.progress = true;

            this.dataservice.getBuilding(this.currentId).subscribe((building) => {
                this.currentItem = building as IBuilding;
                this.breadcrumb.push({ label: 'HOME', url: '/' });
                this.breadcrumb.push({ label: 'stabili', url: '/buildings' });
                this.breadcrumb.push({ label: this.currentItem.name, url: 'javascript:;' });
                this.dataservice.getMillsCheck(this.currentItem.buildingId).subscribe((check) => {
                    this.progress = false;

                    this.check = check as IMillsCheck;
                    this.millsPropertyUser = this.currentItem.totPropertyMills;
                    this.millsUserEnergy = this.currentItem.totEnergyMills;
                    this.millsHeatUser = this.currentItem.totHeatMills;
                });
            });
        });
    }

    ngOnInit(): void { }

    saveData() {
        this.currentItem.totPropertyMills = this.millsPropertyUser;
        this.currentItem.totEnergyMills = this.millsUserEnergy;
        this.currentItem.totHeatMills = this.millsHeatUser;
        this.progress = true;

        this.dataservice.saveBuildings(this.currentItem).subscribe((x) => {
            this.progress = false;

            this.dataservice.toastr.success('Salvataggio eseguito');
        });
    }


    deleteStat() {
        this.dataservice.confirm('Eliminare tutte le statistiche dei contebilizzatori?', ['NO', 'SI']).then(data => {
            if (data == 1) {
                this.dataservice.deleteDeviceStats(this.currentItem.buildingId).subscribe(() => {
                    this.dataservice.toastr.success('Comando eseguito');
                }, () => {

                    this.dataservice.toastr.error('ERRORE');
                })
            }
        })
    }

    validate() {
        return true;
    }

    reCode() {
        this.dataservice.confirm("Annullare il vecchio codice e rigenerarne uno nuovo? ", ['Si', 'No']).then(value => {
            if (value == 0) {
                this.currentItem.code = this.dataservice.getRandomNumber(8);
            }
        })
    }


    delete(item) { }
    showuser(item: IBuilding, admin: boolean) {
        if (admin) {
            const dialogRef = this.dialog.open(UserlistdialogComponent, {
                data: { administrator: item }
            });
            dialogRef.afterClosed().subscribe((result) => { });
        } else {
            const dialogRef = this.dialog.open(UserlistdialogComponent, {
                data: { building: item }
            });
            dialogRef.afterClosed().subscribe((result) => { });
        }
    }

    getStatusIcon(idx: number) {
        switch (idx) {
            case 0:
                console.log('Mills: ', this.millsHeatUser , this.check.millsHeatUser);

                return this.millsUserEnergy === undefined || Math.round(this.millsUserEnergy * 1000) === Math.round(this.check.millsUserEnergy * 1000);
                break;
            case 1:
                console.log('Mills: ', this.millsHeatUser , this.check.millsHeatUser);
                return this.millsHeatUser === undefined || Math.round(this.millsHeatUser * 1000) === Math.round(this.check.millsHeatUser * 1000);

                break;
            case 2:
                return this.millsPropertyUser === undefined || Math.round(this.millsPropertyUser * 1000) === Math.round(this.check.millsPropertyUser * 1000);
                break;
        }
        return false;
    }

    onRadFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileRadName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            this.progress = true;
            this.dataservice.uploadFile('rep', formData).subscribe(() => {
                this.dataservice.toastr.success('Caricamento riuscito');
                this.progress = false;
            });
        }
    }

    onXMLFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileRadName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            this.progress = true;
            this.dataservice.uploadFile('xml', formData).subscribe(() => {
                this.dataservice.toastr.success('Caricamento riuscito');
                this.progress = false;
            });
        }
    }

    updateBuilding() {
        const dialogRef = this.dialog.open(ImportapartmentComponent, {
            data: this.currentItem, width: '90vw', height: '90vh'
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }
}
