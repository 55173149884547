<h1 mat-dialog-title> Unità abitativa in {{currentBuilding?.name}}</h1>
<div mat-dialog-content class="innerdialog">




    <mat-form-field class="field-full-width">
        <mat-label>Nome</mat-label>

        <input matInput placeholder="Nome" [(ngModel)]="currentApartment.name" />
    </mat-form-field>
    <mat-form-field class="field field__floor">
        <mat-label>Numero</mat-label>

        <input matInput placeholder="Numero" [(ngModel)]="currentApartment.number" />
    </mat-form-field>
    <mat-form-field class="field field__floor">
        <mat-label>Piano</mat-label>

        <input matInput placeholder="Piano" [(ngModel)]="currentApartment.floor" />
    </mat-form-field>
    <mat-form-field class="field field__position">
        <mat-label>Scala</mat-label>

        <input matInput placeholder="Scala" [(ngModel)]="currentApartment.staircase" />
    </mat-form-field>
    <mat-form-field class="field field__position">
        <mat-label>Posizione</mat-label>

        <input matInput placeholder="Posizione" [(ngModel)]="currentApartment.position" />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <mat-label>Referente</mat-label>
        <input matInput placeholder="Referente" [(ngModel)]="currentApartment.owner" />
    </mat-form-field>
    <mat-form-field class="field field__phone">
        <mat-label>Telefono 1</mat-label>

        <input matInput placeholder="Telefono 1" [(ngModel)]="currentApartment.ownerphone" />
    </mat-form-field>
    <mat-form-field class="field field__phone">
        <mat-label>Telefono 2</mat-label>

        <input matInput placeholder="Telefono 2" [(ngModel)]="currentApartment.ownerphone2" />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <mat-label>Email</mat-label>

        <input matInput placeholder="Email" [(ngModel)]="currentApartment.owneremail" />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <mat-label>Note</mat-label>

        <input matInput placeholder="Note" [(ngModel)]="currentApartment.note" />
    </mat-form-field>

    <mat-form-field class="field-full-width">
        <mat-label>Millesimi Riscaldamento</mat-label>

        <input matInput placeholder="Millesimi Riscaldamento" [readonly]="dataservice.currentUser.role < 3"
            [(ngModel)]="heatMills" />
    </mat-form-field>

    <mat-form-field class="field-full-width">
        <mat-label>Millesimi Energia</mat-label>

        <input matInput placeholder="Millesimi Energia" [readonly]="dataservice.currentUser.role < 3"
            [(ngModel)]="energyMills" />
    </mat-form-field>

    <mat-form-field class="field-full-width">
        <mat-label>Millesimi Proprietà</mat-label>

        <input matInput placeholder="Millesimi Proprietà" [readonly]="dataservice.currentUser.role < 3"
            [(ngModel)]="propertyMills" />
    </mat-form-field>


</div>
<div mat-dialog-actions class="flexbuttons">
    <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">SALVA</button>
    <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="close()">ANNULLA</button>
</div>