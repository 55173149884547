<div class="tabcontainer">
    <div *ngIf="userConfirm === 0">
        <h2 class="tabtitle">Registra nuovo utente</h2>
        <hr />
        <div class="mainwhite">
            <mat-form-field class="field-full-width">
                <mat-label>Nome</mat-label>
                <input matInput placeholder="Nome" [(ngModel)]="user.firstName" />
            </mat-form-field>
            <mat-form-field class="field-full-width">
                <mat-label>Cognome</mat-label>
                <input matInput placeholder="Cognome" [(ngModel)]="user.lastName" />
            </mat-form-field>

            <mat-form-field class="field-full-width">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" [(ngModel)]="user.email" />
            </mat-form-field>
            <mat-form-field class="field-full-width">
                <mat-label>Telefono</mat-label>
                <input matInput placeholder="Telefono" [(ngModel)]="user.phone" />
            </mat-form-field>
            <mat-form-field class="field-full-width">
                <mat-label>Password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password"
                    [(ngModel)]="user.password" />
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field class="field-full-width">
                <mat-label>Conferma password</mat-label>
                <input matInput [type]="vhide ? 'password' : 'text'" placeholder="Conferma password"
                    [(ngModel)]="passwordConfirm" />
                <button mat-icon-button matSuffix (click)="vhide = !vhide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="vhide">
                    <mat-icon>{{vhide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="field-full-width">
                <mat-label>Codice identificativo fornito da GECO</mat-label>
                <input matInput placeholder="Codice identificativo fornito da GECO" [(ngModel)]="user.code" />
            </mat-form-field>
            <div>
                <a mat-raised-button href="assets/documents/Condizionigeneraliportale.pdf" target="_blank" (click)="downloadGDPR()">VISIONA
                    L'INFORMATIVA SULLA PRIVACY</a>
            </div>

            <div class="acceptgdpr">
                <mat-checkbox [(ngModel)]="confirmGDPR" [disabled]="!gdpr"><b>Obbligatorio</b>: Ho visionato ed accettato il documento
                    (art. 6 lett. b), e) GDPR</mat-checkbox>
            </div>

            <div class="acceptgdpr">
                <mat-checkbox [(ngModel)]="confirmGDPRB" [disabled]="!confirmGDPR"><b>Facoltativo</b>: Do specifico e distinto consenso (art. 7 GDPR), per le finalità di Marketing</mat-checkbox>
            </div>
            <div class="acceptgdpr">
                <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">Registra</button>
            </div>
        </div>
    </div>
    <div *ngIf="userConfirm === 1">
        <h2 class="tabtitle">Registrazione completata</h2>
        <p>
            La registrazione è stata completata con successo, riceverai una mail all'indirizzo <b>{{ user.email }}</b>
            con un link per confermare la tua identità entro 24 ore da ora
        </p>
    </div>
    <div *ngIf="userConfirm === 2">
        <h2 class="tabtitle">Si è verificato un errore</h2>
        <p>Si è verificato un errore del sistema, la registrazione non è andata a buon fine, si prega di riprovare.
            <br />Se il probelma persiste contattare il supporto utenti.
        </p>
    </div>
</div>