import { IBreadcrumbData, ICSVData, ICompany } from 'src/interfaces';
import { IBuilding, ISortParams } from './../../interfaces';
import { DataserviceService } from './../dataservice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BuildingComponent } from '../building/building.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { UserlistdialogComponent } from '../userlistdialog/userlistdialog.component';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { CoverageComponent } from '../coverage/coverage.component';
import { ActivatedRoute } from '@angular/router';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { MatBadge } from '@angular/material/badge';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatIconAnchor, MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf } from '@angular/common';
import { ImportapartmentComponent } from '../importapartment/importapartment.component';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ExportreadsComponent } from '../exportreads/exportreads.component';
import { CsvexportComponent } from '../csvexport/csvexport.component';

@Component({
    selector: 'app-managebuilding',
    templateUrl: './managebuilding.component.html',
    styleUrls: ['./managebuilding.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        ProgressspinnerComponent,
        BreadcrumbnavComponent,
        MatFormField,
        MatLabel,
        MatInput,
        FormsModule,
        MatIconButton,
        MatSuffix,
        MatIcon,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        MatCellDef,
        MatCell,
        MatIconAnchor,
        MatTooltip,
        MatBadge,
        MatMenuTrigger,
        MatMenu,
        MatButton,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatPaginator,
        MatOption, MatSelect,
        CsvexportComponent
    ],
})
export class ManagebuildingComponent implements OnInit {
    dataSource: MatTableDataSource<IBuilding>;

    allItems: IBuilding[] = [];
    sortedData: IBuilding[] = [];
    displayedColumns: string[] = ['number', 'name', 'address', 'devices', 'company', 'action'];
    progress = false;
    @ViewChild(MatSort) sort: MatSort;
    breadcrumb: IBreadcrumbData[] = [];
    toFind = '';
    company: ICompany = undefined;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public dialog: MatDialog, public route: ActivatedRoute, public dataservice: DataserviceService) {
        this.route.paramMap.subscribe(async (map) => {
            const find = map.get('search');
            if (find) {
                this.toFind = find;
            }

        });
    }



    showuser(item: IBuilding, admin: boolean) {
        if (admin) {
            const dialogRef = this.dialog.open(UserlistdialogComponent, {
                data: { administrator: item }
            });
            dialogRef.afterClosed().subscribe((result) => { });
        } else {
            const dialogRef = this.dialog.open(UserlistdialogComponent, {
                data: { building: item }
            });
            dialogRef.afterClosed().subscribe((result) => { });
        }
    }

    opencover(item: IBuilding) {
        const dialogRef = this.dialog.open(CoverageComponent, {
            data: item, width: '90vw',
            height: '90vh'
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }

    delete(item: IBuilding) {
        this.dataservice.confirm("Eliminando l'edificio si elimineranno tutte le unità abitative associate. Procedere?", ['No', 'Si']).then((resp) => {
            if (resp === 1) {
                this.progress = true;
                this.dataservice.deleteBuilding(item).subscribe(async (data) => {
                    this.progress = false;
                    this.dataservice.toastr.success('Eliminazione avvenuta');
                    await this.ngOnInit();
                }, () => {
                    this.progress = false;
                    this.dataservice.toastr.error('Eliminazione fallita');
                });
            }
        });
    }
    /*
        this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
        this.dataSource.paginator = this.paginator;
        // console.log('changed!!', this.toFind);
*/
    delfind() {
        this.toFind = '';

        this.find();
    }
    getFilteredDevices() {
        console.log("xxx");
        const sorted = this.sortedData.filter((x) => this.applyFilter(x));
        return sorted;
    }

    applyFilter(x: IBuilding): boolean {
        if (this.company != undefined) {
            if (x.company == undefined || x.company == null || x.company.companyId != this.company.companyId) {
                return false;
            }
        }
        if (this.toFind.length > 3) {
            if (x.name.toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
            if (this.getAddress(x).toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    SortChange(event) {
        // console.log(event);
        const data = this.allItems.slice();
        const sortParams = event as ISortParams;
        sortParams.enabled = sortParams.direction !== '';
        if (sortParams.enabled) {
            this.sortedData = data.sort((a, b) => {
                switch (sortParams.active) {
                    case 'name':
                        return this.dataservice.compare(a.name, b.name, sortParams.direction);
                    case 'address':
                        return this.dataservice.compare(this.getAddress(a), this.getAddress(b), sortParams.direction);
                    case 'number':
                        return this.dataservice.compareInt(parseInt(a.number), parseInt(b.number), sortParams.direction);
                    default:
                        return 0;
                }
            });
        } else {
            this.sortedData = this.allItems.slice();
        }
        this.find();
    }

    find() {

        this.dataservice.lastBuildingSearch = this.toFind;

        localStorage.setItem('find', this.toFind);

        this.dataSource = new MatTableDataSource<IBuilding>(this.getFilteredDevices());
        this.dataSource.paginator = this.paginator;
    }

    async ngOnInit(): Promise<void> {
        this.progress = true;
        console.log('Init ***********************');
        this.toFind = localStorage.getItem('find') || '';
        if (this.dataservice.currentUser === undefined) {
            await this.dataservice.whoAmIBlocking();
        }
        if (this.dataservice.currentUser.role > 2) {
            this.dataservice.getBuildings().subscribe((data) => {
                this.allItems = data as IBuilding[];
                this.sortedData = this.allItems.slice();
                this.dataSource = new MatTableDataSource<IBuilding>(this.getFilteredDevices());
                this.dataSource.paginator = this.paginator;
                this.progress = false;

                this.find();
            });
        } else {
            this.allItems = this.dataservice.currentUser.buildings;
            this.sortedData = this.allItems.slice();
            this.dataSource = new MatTableDataSource<IBuilding>(this.getFilteredDevices());
            this.dataSource.paginator = this.paginator;
            this.progress = false;
            this.find();
        }
        this.breadcrumb.push({ label: 'HOME', url: '/' });
        this.breadcrumb.push({ label: 'STABILI', url: 'javascript:;' });

    }

    getAddress(item: IBuilding) {
        return (item.dug || '') + ' ' + (item.address || '') + ' ' + (item.number || '') + ', ' + (item.zip || '') + ' ' + (item.city || '') + ' (' + (item.province || '') + ') ';
    }

    select(item: IBuilding) { }

    edit(item: IBuilding) {
        const dialogRef = this.dialog.open(BuildingComponent, {
            data: item
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // console.log(result);
                this.dataservice.saveBuildings(result as IBuilding).subscribe((x) => {
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.ngOnInit().then(() => { });
                });
            }
        });
    }

    addNew() {
        const dialogRef = this.dialog.open(BuildingComponent, {
            data: {} as IBuilding
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // console.log(result);
                this.dataservice.addBuildings(result as IBuilding).subscribe((x) => {
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.ngOnInit().then(() => { });
                });
            }
        });
    }

    import() {
        const dialogRef = this.dialog.open(ImportapartmentComponent, {
            data: undefined, width: '90vw', height: '90vh'
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.ngOnInit();
        });
    }

    exportBuildiongData(element: IBuilding) {
        this.progress = true;
        this.dataservice.getExportBuildingData(element).subscribe(data => {
            const csv: ICSVData = {
                content: (data as any).response,
                title: 'stabile ' + element.name,
                filename: 'stabile_' + element.name + '.csv'
            }
            console.log(csv);
            this.progress = false;
            const dialogRef = this.dialog.open(CsvexportComponent, {
                data: csv, width: '90vw', height: '90vh'
            });

            dialogRef.afterClosed().subscribe((result) => { });
        });
    }

    exportdata(item: IBuilding) {
        const dialogRef = this.dialog.open(ExportreadsComponent, {
            data: item, width: '90vw', height: '90vh'
        });

        dialogRef.afterClosed().subscribe((result) => { });
    }
}
