import { IBreadcrumbData } from './../../interfaces';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { IApartment, IBuilding, ISortParams } from 'src/interfaces';
import { ApartmentComponent } from '../apartment/apartment.component';
import { DataserviceService } from '../dataservice.service';
import { UserlistdialogComponent } from '../userlistdialog/userlistdialog.component';
import { ExportreadsComponent } from '../exportreads/exportreads.component';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { MatBadge } from '@angular/material/badge';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconAnchor, MatIconButton, MatButton } from '@angular/material/button';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MenutabComponent } from '../menutab/menutab.component';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { BreadcrumbnavComponent } from '../breadcrumbnav/breadcrumbnav.component';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { AddapartmentComponent } from '../addapartment/addapartment.component';

@Component({
    selector: 'app-manageapartment',
    templateUrl: './manageapartment.component.html',
    styleUrls: ['./manageapartment.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, ProgressspinnerComponent, BreadcrumbnavComponent, MatFormField, MatLabel, MatInput, FormsModule, NgFor, MenutabComponent, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatIconAnchor, MatTooltip, MatIcon, MatBadge, MatIconButton, MatMenuTrigger, MatMenu, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, MatButton]
})
export class ManageapartmentComponent implements OnInit {
    dataSource: MatTableDataSource<IApartment>;

    allItems: IApartment[] = [];
    sortedData: IApartment[] = [];
    currentId: number;
    currentBuilding: IBuilding;
    key: string;
    displayedColumns: string[] = ['number', 'name', 'updated', 'stair', 'floor', 'phone', 'email', 'code', 'note', 'action'];
    progress = false;
    toFind = '';
    breadcrumb: IBreadcrumbData[] = [];
    showArchived = false;
    showActive = true;
    archived = 0;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    active = 0;

    constructor(public dialog: MatDialog, private route: ActivatedRoute, public dataservice: DataserviceService) {

    }
    /*
    find() {
        // console.log('Tofind', this.toFind);
        if (this.toFind.length > 3) {
            const items = this.allItems.filter((x) => x.name.toLowerCase().indexOf(this.toFind.toLowerCase()) !== -1);
            this.dataSource = new MatTableDataSource<IApartment>(items);
            this.dataSource.paginator = this.paginator;
        } else {
            this.dataSource = new MatTableDataSource<IApartment>(this.allItems);
            this.dataSource.paginator = this.paginator;
        }
    }*/

    getFilteredDevices() {
        const sorted = this.sortedData.filter((x) => this.applyFilter(x));
        return sorted;
    }
    getPhone(element: IApartment) {
        let sep = '';
        let retval = '';
        if (element.ownerphone && element.ownerphone.length > 1) {
            retval += element.ownerphone;
            sep = ' + ';
        }

        if (element.ownerphone2 && element.ownerphone2.length > 1) {
            retval += sep + element.ownerphone2;

        }

        return retval;
    }
    sel(key: string) {
        if (this.archived > 0) {


            switch (key) {
                case 'all':
                    this.showActive = !this.showActive;
                    if (!this.showActive && !this.showArchived) {
                        this.showArchived = true;
                    }
                    break;
                case 'arc':
                    this.showArchived = !this.showArchived;
                    if (!this.showActive && !this.showArchived) {
                        this.showActive = true;
                    }
                    break;

            }
        }
        this.find();
    }

    applyFilter(x: IApartment): boolean {
        if (!this.showArchived && x.isArchived) {
            return false;
        }
        if (!this.showActive && !x.isArchived) {
            return false;
        }
        if (this.toFind.length > 2) {
            if (x.name.toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    geCodes(id: number) {
        this.dataservice.getBuildingcode(id);
    }

    getStatus(item: IApartment) {
        if (item.metersCount == 0 || item.metersCount == item.metersInvalid) {
            return 'black';
        }
        if (item.metersNotUpdated === item.metersCount) {
            return 'red';
        }
        if (item.metersNotUpdated > 0) {
            return 'orange';
        }
        return 'green';
    }
    defSort() {
        const data = this.allItems.slice();
        this.sortedData = data.sort((a, b) => {

            let retval = 0;
            if (a.staircase === b.staircase) {
                if (a.number === b.number) {
                    return this.dataservice.compare(a.floor, b.floor, 'asc');
                }
                return this.dataservice.compare(a.number, b.number, 'asc');
            }
            return this.dataservice.compare(a.staircase, b.staircase, 'asc');

        });
        this.find();
    }
    SortChange(event) {
        // console.log(event);
        const data = this.allItems.slice();
        const sortParams = event as ISortParams;
        sortParams.enabled = sortParams.direction !== '';
        if (sortParams.enabled) {
            this.sortedData = data.sort((a, b) => {
                switch (sortParams.active) {
                    case 'updated':
                        return this.dataservice.compare(a.metersNotUpdated, b.metersNotUpdated, sortParams.direction);
                    case 'name':
                        return this.dataservice.compare(a.name, b.name, sortParams.direction);
                    case 'stair':
                        return this.dataservice.compare(a.staircase, b.staircase, sortParams.direction);
                    case 'floor':
                        return this.dataservice.compare(a.floor, b.floor, sortParams.direction);
                    case 'phone':
                        return this.dataservice.compare(a.ownerphone || b.ownerphone2, b.ownerphone || b.ownerphone2, sortParams.direction);
                    case 'default': {
                        let retval = 0;
                        if (a.staircase === b.staircase) {
                            if (a.number === b.number) {
                                return this.dataservice.compare(a.floor, b.floor, sortParams.direction);
                            }
                            return this.dataservice.compare(a.number, b.number, sortParams.direction);
                        }
                        return this.dataservice.compare(a.staircase, b.staircase, sortParams.direction);
                    }
                    default:
                        return 0;
                }
            });
        } else {
            this.sortedData = this.allItems.slice();
        }
        this.find();
    }

    find() {
        this.dataSource = new MatTableDataSource<IApartment>(this.getFilteredDevices());
        this.dataSource.paginator = this.paginator;
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(async (map) => {
            this.progress = true;
            // console.log('Pmap; ', map.get('id'));
            this.key = map.get('id');
            if (this.key === 'my') {
                this.allItems = this.dataservice.currentUser.apartments;
                this.sortedData = this.allItems.slice();
                /*  this.breadcrumb.push({ label: 'HOME', url: '/' });
                  this.breadcrumb.push({ label: 'STABILI', url: '/buildings' });
                  this.breadcrumb.push({ label: this.currentBuilding.name, url: '/building/' + this.currentBuilding.buildingId });
                  this.breadcrumb.push({ label: 'Unità abitative', url: 'javascript:;' });*/

                this.dataSource = new MatTableDataSource<IApartment>(this.sortedData);
                this.dataSource.paginator = this.paginator;
                this.progress = false;

            } else {
                this.currentId = parseInt(this.key, 10);
                this.dataservice.getBuilding(this.currentId).subscribe((building) => {
                    this.currentBuilding = building as IBuilding;
                    this.breadcrumb.push({ label: 'HOME', url: '/' });
                    this.breadcrumb.push({ label: 'STABILI', url: '/buildings' });
                    this.breadcrumb.push({ label: this.currentBuilding.name, url: '/building/' + this.currentBuilding.buildingId });
                    this.breadcrumb.push({ label: 'Unità abitative', url: 'javascript:;' });

                    this.dataservice.getApartmets(this.currentId).subscribe((data) => {
                        this.progress = false;

                        this.allItems = data as IApartment[];
                        this.archived = this.allItems.filter(x => x.isArchived).length;
                        this.active = this.allItems.filter(x => !x.isArchived).length;
                        this.sortedData = this.allItems.slice();
                        this.find();
                        // this.dataSource = new MatTableDataSource<IApartment>(this.sortedData);
                        // this.dataSource.paginator = this.paginator;
                    });
                });
            }
        });

    }

    showuser(item: IApartment) {
        const dialogRef = this.dialog.open(UserlistdialogComponent, {
            data: { apartment: item }
        });

        dialogRef.afterClosed().subscribe((result) => { });
    }

    export() {
        const dialogRef = this.dialog.open(ExportreadsComponent, {
            data: this.currentBuilding, width: '90vw', height: '90vh'
        });

        dialogRef.afterClosed().subscribe((result) => { });
    }

    select(row: IApartment) {
        const dialogRef = this.dialog.open(ApartmentComponent, {
            data: { ...row }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // console.log(result);
                const item = result as IApartment;
                item.building = { buildingId: this.currentId } as IBuilding;
                this.dataservice.saveApartment(item).subscribe((x) => {
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.dataservice.getApartmets(this.currentId).subscribe((data) => {
                        this.progress = false;

                        this.allItems = data as IApartment[];
                        this.sortedData = this.allItems.slice();

                        this.dataSource = new MatTableDataSource<IApartment>(this.sortedData);
                        this.dataSource.paginator = this.paginator;
                    });
                });
            }
        });
    }

    addNew() {
        const dialogRef = this.dialog.open(AddapartmentComponent, {
            data: this.currentBuilding,
            width: '90vw',
            height: '90vh'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // console.log(result);
                const item = result as IApartment;
                item.building = { buildingId: this.currentId } as IBuilding;

                this.dataservice.getApartmets(this.currentId).subscribe((data) => {
                    this.progress = false;

                    this.allItems = data as IApartment[];
                    this.sortedData = this.allItems.slice();

                    this.dataSource = new MatTableDataSource<IApartment>(this.sortedData);
                    this.dataSource.paginator = this.paginator;
                });

            }
        });
    }
}
