import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { DataserviceService } from '../dataservice.service';
import { MatButton, MatIconButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { IMeterDevice, IMeterDeviceBillingInfo, IMeterDeviceStatBillingInfo, IMeterDeviceStatBillingInfoString, IMeterDeviceStats } from 'src/interfaces';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatIcon } from '@angular/material/icon';
import { MatOptgroup, MatOption, MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-billcustomstat',
    templateUrl: './billcustomstat.component.html',
    styleUrls: ['./billcustomstat.component.scss'],
    standalone: true,
    imports: [MatTooltip, MatOption, MatSelect, MatIconButton, MatIcon, MatRadioButton, MatRadioGroup, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, MatDialogActions, MatButton]
})
export class BillcustomstatComponent {
    editDevice: IMeterDeviceBillingInfo;
    apartment: string;
    value: string;
    readoutValue: string;
    fakeData: string;
    dataCalcMode: number;
    currentDevice: IMeterDevice;
    deviceSetValue: IMeterDeviceStats;

    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<BillcustomstatComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log("aua");
        this.editDevice = data.device as IMeterDeviceBillingInfo;
        this.apartment = data.apartment as string;

        this.value = dataservice.getMediumValue(this.editDevice.medium, this.editDevice.billTotal);
        this.readoutValue = dataservice.getMediumValue(this.editDevice.medium, this.editDevice.readoutTotal);
        this.fakeData = dataservice.getMediumValue(this.editDevice.medium, this.editDevice.fakeData);
        this.dataCalcMode = this.editDevice.dataCalcMode;
        this.dataservice.getMeterDetail(this.editDevice.meterDeviceId).subscribe(data => {
            this.currentDevice = data as IMeterDevice;
            this.currentDevice.statistics.sort((a, b) => b.monthFrom2k - a.monthFrom2k);
            this.deviceSetValue = {
                readedValue: this.currentDevice.readoutSetValue,
                value: this.currentDevice.setValue,
                monthFrom2k: this.editDevice.setDate

            } as IMeterDeviceStats;
        })

    }

    setValue(item: IMeterDeviceStats) {
        switch (this.dataCalcMode) {
            case 0:
                break;
            case 1:
                this.fakeData = this.dataservice.getMediumValue(this.editDevice.medium, item.readedValue);
                break;
            case 2:
                this.fakeData = this.dataservice.getMediumValue(this.editDevice.medium, item.value);
                break;

        }
    }
    saveData() {
        const tempStat: IMeterDeviceBillingInfo = {
            meterDeviceId: this.currentDevice.meterDeviceId,
            total: this.dataservice.setMediumValue(this.editDevice.medium, this.value),
            readoutTotal: this.dataservice.setMediumValue(this.editDevice.medium, this.readoutValue),
            fakeData: this.dataservice.setMediumValue(this.editDevice.medium, this.fakeData),
            dataCalcMode: this.dataCalcMode
        } as IMeterDeviceBillingInfo;
        this.dialogRef.close([this.fakeData, this.dataCalcMode]);


      /*  this.dataservice.recalcBillingStat(tempStat).subscribe(data => {
            const billValue = this.dataservice.getMediumValue(this.editDevice.medium, (data as number));
            this.dialogRef.close([this.value, this.readoutValue, billValue, this.dataCalcMode]);
        })*/
    }

    close() {
        this.dialogRef.close();
    }

    validate() {
        return true;
    }

    reload() {
        const tempStat: IMeterDeviceBillingInfo = {
            meterDeviceId: this.currentDevice.meterDeviceId,
            total: this.dataservice.setMediumValue(this.editDevice.medium, this.value),
            readoutTotal: this.dataservice.setMediumValue(this.editDevice.medium, this.readoutValue),
            fakeData: this.dataservice.setMediumValue(this.editDevice.medium, this.fakeData),
            dataCalcMode: this.dataCalcMode
        } as IMeterDeviceBillingInfo;


        this.dataservice.recalcBillingStat(tempStat).subscribe(data => {
            this.value = this.dataservice.getMediumValue(this.editDevice.medium, (data as number));
        })
    }
}
