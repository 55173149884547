import { IBuilding, IRadiatorParameter, ISortParams } from './../../interfaces';
import { DataserviceService } from './../dataservice.service';
import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFooterCellDef, MatFooterCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { ProgressspinnerComponent } from '../progressspinner/progressspinner.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-manageradiatorlist',
    templateUrl: './manageradiatorlist.component.html',
    styleUrls: ['./manageradiatorlist.component.scss'],
    standalone: true,
    imports: [NgIf, ProgressspinnerComponent, MatFormField, MatLabel, MatInput, FormsModule, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatFooterCellDef, MatFooterCell, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow, MatPaginator, MatButton]
})
export class ManageradiatorlistComponent {
    displayedColumns: string[] = ['code', 'type', 'uni', 'cj', 'k75_2f_205','k75_2f_205_SR', 'sontexKC1', 'sontexKC2', 'sontexKCSR', 'image', 'action'];

    dataSource: MatTableDataSource<IRadiatorParameter>;
    allItems: IRadiatorParameter[];
    sortedData: IRadiatorParameter[];
    toFind = '';
    newItem: IRadiatorParameter = {} as IRadiatorParameter;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    progress = false;
    sortParams: ISortParams = {} as ISortParams;

    constructor(public dataservice: DataserviceService) {
        this.dataservice.getRadiators().subscribe((data) => {
            this.allItems = data as IRadiatorParameter[];
            this.sortedData = this.allItems.slice();
            this.dataSource = new MatTableDataSource<IRadiatorParameter>(this.getFilteredDevices());
            this.dataSource.paginator = this.paginator;
            this.progress = false;
        });
    }

    SortChange(event) {
        // console.log(event);
        const data = this.allItems.slice();
        this.sortParams = event as ISortParams;
        this.sortParams.enabled = this.sortParams.direction !== '' || this.sortParams !== undefined;
        if (this.sortParams.enabled) {
            this.sortedData = data.sort((a, b) => {
                switch (this.sortParams.active) {
                    case 'code':
                        return this.dataservice.compare(a.code, b.code, this.sortParams.direction);
                    case 'type':
                        return this.dataservice.compare(a.type, b.type, this.sortParams.direction);
                    case 'uni':
                        let aa = 255;
                        if (isNumeric(a.uni)) {
                            aa = parseInt(a.uni, 10);
                        }
                        let bb = 255;
                        if (isNumeric(b.uni)) {
                            bb = parseInt(b.uni, 10);
                        }
                        return this.dataservice.compareInt(aa, bb, this.sortParams.direction);

                    default:
                        return 0;
                }
            });
        } else {
            this.sortedData = this.allItems.slice();
        }
        this.find();
    }

    find() {
        this.dataSource = new MatTableDataSource<IRadiatorParameter>(this.getFilteredDevices());
        this.dataSource.paginator = this.paginator;
    }
    getFilteredDevices() {
        const sorted = this.sortedData.filter((x) => this.applyFilter(x));
        return sorted;
    }

    applyFilter(x: IRadiatorParameter): boolean {
        if (this.toFind.length > 3) {
            if (x.code.toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    save() {
        this.dataservice.downloadFile(this.allItems);
    }

    confirm(item: IRadiatorParameter) {
        console.log("save");
        if (this.isValid(item)) {
            this.dataservice.saveRadiator(item).subscribe(
                (data) => {
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.allItems = data as IRadiatorParameter[];
                    this.SortChange(this.sortParams);

                    this.progress = false;
                },
                () => {
                    this.dataservice.toastr.error('errore durante il savataggio');
                }
            );
        }
    }
    isValid(item: IRadiatorParameter): boolean {
        if (item.code && item.cj > 0) {
            return true;
        }
        return false;
    }

    add() {
        if (this.isValid(this.newItem)) {
            this.dataservice.addRadiator(this.newItem).subscribe(
                (data) => {
                    this.newItem = { code: '' } as IRadiatorParameter;
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.allItems = data as IRadiatorParameter[];
                    this.SortChange(this.sortParams);
                    this.progress = false;
                },
                () => {
                    this.dataservice.toastr.error('errore durante il savataggio');
                }
            );
        }
    }
}

function isNumeric(value) {
    return /^-?\d+$/.test(value);
}
