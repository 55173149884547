<h2 mat-dialog-header>Lettura manuale {{apartment}} - {{currentDevice?.serial}} {{currentDevice?.name}}</h2>
@if (currentDevice) {
<mat-dialog-content>
    <div>Medium: {{this.dataservice.getMediumName(currentDevice.medium)}}</div>
    @if (this.currentDevice.medium===8) {
    @if (currentDevice.deviceDetails.k===0) {
    <div>Valore K: N/A</div>
    }
    @else {
    <div>Valore K: {{currentDevice.deviceDetails.k.toFixed(0)}}</div>
    }
    }
    <hr />
    @if (currentDevice.medium===8) {
    <mat-form-field>
        <mat-label>Forza lettura</mat-label>
        <mat-select [(ngModel)]="dataCalcMode">
            <mat-option [value]=0>Dato originale</mat-option>
            @if (currentDevice.deviceDetails.k!==0) {
            <mat-option [value]=1>Forza dato senza K</mat-option>
            }
            <mat-option [value]=2>Forza dato con K calcolato</mat-option>
            <mat-option [value]=3>Dato mancante</mat-option>

        </mat-select>
    </mat-form-field>
    } @else {
    <mat-form-field>
        <mat-label>Forza lettura</mat-label>
        <mat-select [(ngModel)]="dataCalcMode">
            <mat-option [value]=0>Dato originale</mat-option>
            <mat-option [value]=2>Forza lettura</mat-option>
            <mat-option [value]=4>Media appartamento</mat-option>
            <mat-option [value]=3>Dato mancante</mat-option>
        </mat-select>
    </mat-form-field>
    }

    <mat-form-field>
        <mat-label>Valore</mat-label>
        @switch (dataCalcMode) {
        @case (0) {
        <input matInput placeholder="Valore letto" [(ngModel)]="readoutValue" disabled="1" />

        }
        @case (1) {
        <input matInput placeholder="Valore letto" [(ngModel)]="fakeData" />
        }
        @case (2) {
        <input matInput placeholder="Valore letto" [(ngModel)]="fakeData" />
        }
        @case (3) {
        <input matInput placeholder="Valore letto" value="N/A" disabled="1" />
        }
        @case (4) {
        <input matInput placeholder="Valore calcolato" [(ngModel)]="fakeData" disabled="1" />

        }
        }

    </mat-form-field>
    <button mat-icon-button (click)="reload()"><mat-icon>restart_alt</mat-icon></button>
    <mat-form-field>
        <input matInput placeholder="Calcolato" [(ngModel)]="value" disabled="1" />
    </mat-form-field>
    <div class="statContainer">

        <div class="statContainer__item statContainer__item--set" (click)="setValue(deviceSetValue)"
            matTooltipClass="devTT"
            [matTooltip]="'Valore di SET\n'+dataservice.getBillStartDateFull(deviceSetValue.monthFrom2k)">
            <div>{{dataservice.getBillStartDate(deviceSetValue.monthFrom2k)}}</div>
            @if (currentDevice.medium===8) {
            <div>{{deviceSetValue.value}} [{{deviceSetValue.readedValue}}]</div>

            } @else {
            <div>{{deviceSetValue.readedValue}}</div>
            }


        </div>

        @for (item of currentDevice.statistics; track $index) {
        <div class="statContainer__item" (click)="setValue(item)" matTooltipClass="devTT"
            [matTooltip]="'Valore statistico\n'+dataservice.getBillStartDateFull(item.monthFrom2k)">
            <div>{{dataservice.getBillStartDate(item.monthFrom2k)}}</div>
            @if (currentDevice.medium===8) {
            <div>{{item.value}} [{{item.readedValue}}]</div>

            } @else {
            <div>{{item.readedValue}}</div>
            }


        </div>
        }
    </div>
    @if (currentDevice.medium===8) {
    <div>Legenda: Valore calcolato [Valore letto]</div>
    }
</mat-dialog-content>
}
<mat-dialog-actions>
    <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">SALVA</button>
    <button mat-raised-button class="ideb" (click)="close()">ANNULLA</button>
</mat-dialog-actions>